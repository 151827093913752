import axios from 'axios';
import Listener from '../Utils/Listen';

//apply base url for axios
const REACT_APP_APP_URL = 'https://borneotrans-api.amazingborneo.id/';
// const REACT_APP_APP_URL = 'http://localhost:3030';

const axiosApi = axios.create({
  baseURL: REACT_APP_APP_URL
});

axios.interceptors.request.use(function (config) {
  return config;
});

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

const successText = 'Data berhasil disimpan';
const successUpdate = 'Data berhasil diubah';
const successDelete = 'Data berhasil dihapus';

const emitListener = (status, msg) => {
  const payload = {
    status: status,
    message: status === 'error' ? msg.response?.data?.errors[0]?.message : msg
  };
  Listener.emit('refresh', payload);
  Listener.emit('createToast', payload);
};

export async function post(url, config) {
  return await axiosApi
    .post(url, {
      ...config
    })
    .then((response) => {
      emitListener('success', successText);
      return response.data;
    })
    .catch((error) => emitListener('success', error));
}

export async function get(url, config) {
  return await axiosApi
    .get(url, {
      ...config
    })
    .then((response) => response.data);
}

export async function patch(url, { id, payload }) {
  return await axiosApi
    .patch(`${url}/${id}`, {
      ...payload
    })
    .then((response) => {
      emitListener('success', successUpdate);
    })
    .catch((error) => {
      emitListener('success', error);
    });
}

export async function update(url, { id, payload }) {
  return await axiosApi
    .put(`${url}/${id}`, {
      ...payload
    })
    .then((response) => {
      emitListener('success', successUpdate);
    })
    .catch((error) => {
      emitListener('success', error);
    });
}

export async function del(url, id) {
  return await axiosApi
    .delete(`${url}/${id}`)
    .then((response) => {
      emitListener('success', successDelete);
    })
    .catch((error) => {
      emitListener('success', error);
    });
}
