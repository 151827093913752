import React from 'react';

export default function index(props) {
  return (
    <div className="navbar bg-neutral text-neutral-content rounded-md drop-shadow-lg px-4">
      <div className="normal-case text-xl text-white w-full flex flex-row justify-between items-center">
        {/* <h1 className={'font-extrabold text-2xl'}></h1> */}
        {props.children}
      </div>
    </div>
  );
}
