import { mailValidationRegex } from './Regex';

const mailValidation = (mail) => {
  return mail.match(mailValidationRegex);
};

const textLength = (text, length) => {
  return text.length < length;
};

export { mailValidation, textLength };
