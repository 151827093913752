import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { postCreateCity, getCityDatas, removeCity } from '../../Actions/action';
import { Button, Modal, Pagination } from '../../Components';
import Listen from '../../Utils/Listen';

export default function index() {
  const dispatch = useDispatch();
  const states = useSelector((states) => states.MainReducers);
  const [showModal, setShowModal] = React.useState(false);
  const [refresh, setRefresh] = React.useState('');
  const [startNo, setStartNumber] = React.useState(0);
  const [values, setValue] = React.useState({
    name: '',
    stop_place: ''
  });
  const [body, setBody] = React.useState({
    skip: 0,
    limit: 10
  });

  React.useEffect(() => {
    dispatch(getCityDatas(body));
  }, [refresh]);

  const getCity = (skip) => {
    setStartNumber(skip);
    const body = {
      skip
    };
    dispatch(getCityDatas(body));
  };

  React.useEffect(() => {
    Listen.addListener('refresh', eventResponse);
    return () => Listen.removeListener('refresh', eventResponse);
  }, []);

  const eventResponse = (payload) => {
    if (payload.status !== 'error') {
      closeModal();
      setShowModal(false);
      setRefresh(Math.random());
    }
  };

  const resetState = () => {
    setValue({
      name: '',
      stop_place: ''
    });
  };

  const closeModal = () => {
    setShowModal(!showModal);
    resetState();
  };

  const createDestination = () => {
    dispatch(postCreateCity(values));
  };

  const deleteDestination = (v) => {
    let text = `Apakah anda akan menghapus ${v.name} dari daftar destinasi?`;
    if (confirm(text) == true) {
      dispatch(removeCity(v.id));
    } else {
      console.log('cancel delete');
    }
  };

  const handleChange = (prop) => (event) => {
    setValue({ ...values, [prop]: event.target.value });
  };

  const renderTable = () => {
    return (
      <div className="flex flex-col ">
        <div className="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
          <div className="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">
            <table className="min-w-full">
              <thead>
                <tr>
                  <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-50">
                    No
                  </th>
                  <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-50">
                    Kota Keberangkatan
                  </th>
                  <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-50">
                    Lokasi Pemberhentian
                  </th>
                  <th className="text-xs font-medium leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-50">
                    Hapus
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {states.cityDesinationDatas &&
                  states.cityDesinationDatas.data.map((v, i) => (
                    <tr key={`datas-${i}`}>
                      <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                        <div className="text-sm font-medium leading-5 text-gray-900">
                          {startNo + i + 1}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                        <div className="text-sm font-medium leading-5 text-gray-900">{v.name}</div>
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                        <div className="text-sm font-medium leading-5 text-gray-900">
                          {v.stop_place}
                        </div>
                      </td>
                      <td className=" text-sm leading-5 text-gray-500 whitespace-no-wrap border-b border-gray-200">
                        <svg
                          onClick={() => deleteDestination(v)}
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-6 h-6 text-red-400 hover:cursor-pointer"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor">
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                          />
                        </svg>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <Pagination
              datas={states.cityDesinationDatas?.total ?? 0}
              getDatas={(v) => getCity(v)}
            />
          </div>
        </div>
      </div>
    );
  };

  const _renderModal = () => {
    return (
      <Modal
        show={showModal}
        setModalClose={() => setShowModal(!showModal)}
        title={`Tambah Kota Destinasi`}
        onSubmit={() => createDestination()}>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Nama Kota Destinasi</label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="destination"
            type="text"
            placeholder="ex. Pontianak, Sambas etc"
            value={values.name}
            onChange={handleChange('name')}
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Titik Berhenti</label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="destination"
            type="text"
            placeholder="ex Bus place, Terminal etc"
            value={values.stop_place}
            onChange={handleChange('stop_place')}
          />
        </div>
      </Modal>
    );
  };

  const _renderBody = () => {
    return (
      <>
        <div className={`text-xl bg-white p-4 rounded-md drop-shadow-lg mt-4 flex justify-end`}>
          <Button label={'Tambah Data'} type={'primary'} cb={() => setShowModal(!showModal)} />
        </div>
        <div className={`text-xl bg-white p-4 rounded-md drop-shadow-lg mt-4`}>{renderTable()}</div>
      </>
    );
  };

  return (
    <div>
      {_renderBody()} {_renderModal()}
    </div>
  );
}
