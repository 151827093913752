import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBannerDatas, patchBanner, removeBanner } from '../../Actions/action';
import { Button, Modal, Switch } from '../../Components';
import Listen from '../../Utils/Listen';

const URL = 'https://borneotrans-api.amazingborneo.id';
// const URL = 'http://localhost:3030/'

export default function index() {
  const dispatch = useDispatch();
  const states = useSelector((states) => states.MainReducers);
  const [showModal, setShowModal] = React.useState(false);
  const [values, setValue] = React.useState({
    file: null,
    name: '',
    folder: 'banner',
    alt: '',
    status: false
  });
  const [refresh, setRefresh] = React.useState(null);

  React.useEffect(() => {
    dispatch(getBannerDatas());
  }, [refresh]);

  React.useEffect(() => {
    Listen.addListener('refresh', eventResponse);
    return () => Listen.removeListener('refresh', eventResponse);
  }, []);

  const eventResponse = (payload) => {
    if (payload.status !== 'error') {
      setRefresh(Math.random());
      closeModal();
    }
  };

  const resetState = () => {
    setValue({
      file: '',
      folder: '',
      name: '',
      status: false,
      alt: ''
    });
  };

  const closeModal = () => {
    setShowModal(false);
    resetState();
  };

  const createBanner = () => {
    let formData = new FormData();
    formData.append('file', values.file[0]);
    formData.append('folder', values.folder);
    formData.append('alt', values.alt);
    formData.append('name', values.name);
    formData.append('status', values.status);
    fetch(`${URL}/file-upload`, {
      method: 'POST',
      body: formData
    })
      .then((response) => response.json())
      .then((result) => {
        const payload = {
          status: 'success',
          message: 'Banner berhasil di tambahkan'
        };
        Listen.emit('refresh', payload);
        Listen.emit('createToast', payload);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const setStatus = (banner) => {
    const body = {
      id: banner.id,
      payload: {
        status: !banner.status
      }
    };

    dispatch(patchBanner(body));
  };

  const deleteBanner = (v) => {
    let text = `Apakah anda akan menghapus ${v.name} dari daftar destinasi?`;
    if (confirm(text) == true) {
      dispatch(removeBanner(v.id));
    } else {
      console.log('cancel delete');
    }
  };

  const handleChange = (prop) => (event) => {
    setValue({ ...values, [prop]: event.target.value });
  };

  const handleChangeFile = (event) => {
    setValue({ ...values, file: event.target.files });
  };

  const renderTable = () => {
    return (
      <div className="flex flex-col ">
        <div className="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
          <div className="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">
            <table className="min-w-full">
              <thead>
                <tr>
                  <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-50">
                    No
                  </th>
                  <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-50">
                    Nama Banner
                  </th>
                  <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-50">
                    Thumbnail
                  </th>
                  <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-50">
                    Status
                  </th>
                  <th className="text-xs font-medium leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-50">
                    Hapus
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {states.bannerDatas &&
                  states.bannerDatas.map((v, i) => (
                    <tr key={`datas-${i}`}>
                      <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                        <div className="text-sm font-medium leading-5 text-gray-900">{i + 1}</div>
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                        <div className="text-sm font-medium leading-5 text-gray-900">{v.name}</div>
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                        <img
                          src={`${URL}/uploads/banner/${v.filename}`}
                          alt={v.alt}
                          loading="lazy"
                          className={'w-56 bg-slate-300 rounded'}
                        />
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                        <div className="text-sm font-medium leading-5 text-gray-900">
                          <Switch checked={v.status} onSwitch={() => setStatus(v)}></Switch>
                          {}
                        </div>
                      </td>
                      <td className=" text-sm leading-5 text-gray-500 whitespace-no-wrap border-b border-gray-200">
                        <svg
                          onClick={() => deleteBanner(v)}
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-6 h-6 text-red-400 hover:cursor-pointer"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor">
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                          />
                        </svg>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };

  const _renderModal = () => {
    return (
      <Modal
        show={showModal}
        setModalClose={() => setShowModal(!showModal)}
        title={`Tambah Banner`}
        onSubmit={() => createBanner()}>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Nama Banner</label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="destination"
            type="text"
            placeholder="Banner Promo"
            value={values.name}
            onChange={handleChange('name')}
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Banner</label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="file"
            name="files"
            onChange={handleChangeFile}
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">alt image</label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="destination"
            type="text"
            placeholder="ex. imagepromowisata"
            value={values.alt}
            onChange={handleChange('alt')}
          />
        </div>
      </Modal>
    );
  };

  const _renderBody = () => {
    return (
      <>
        <div className={`text-xl bg-white p-4 rounded-md drop-shadow-lg mt-4 flex justify-end`}>
          <Button label={'Tambah Data'} type={'primary'} cb={() => setShowModal(!showModal)} />
        </div>
        <div className={`text-xl bg-white p-4 rounded-md drop-shadow-lg mt-4`}>{renderTable()}</div>
      </>
    );
  };

  return (
    <div>
      {_renderBody()} {_renderModal()}
    </div>
  );
}
