import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getArticleDatas, patchArticle, removeArticle } from '../../Actions/action';
import { Button, Modal, Pagination } from '../../Components';
import Listen from '../../Utils/Listen';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const URL = 'https://borneotrans-api.amazingborneo.id';
// const URI = 'http://localhost:3030';

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    ['link'],
    ['clean'],
    [{ size: ['small', false, 'large', 'huge'] }]
  ]
};

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'size'
];

export default function index() {
  const dispatch = useDispatch();
  const states = useSelector((states) => states.MainReducers);
  const [showModal, setShowModal] = React.useState(false);
  const [values, setValue] = React.useState({
    title: '',
    files: null,
    folder: 'article',
    alt: ''
  });
  const [article, setArticle] = React.useState('');
  const [refresh, setRefresh] = React.useState('');
  const [isEdit, setIsEdit] = React.useState(false);
  const [id, setID] = React.useState(null);
  const [body, setBody] = React.useState({
    skip: 0,
    limit: 10
  });
  const [selectedImages, setSelectedImages] = React.useState([]);

  React.useEffect(() => {
    dispatch(getArticleDatas(body));
  }, [refresh]);

  React.useEffect(() => {
    Listen.addListener('refresh', eventResponse);
    return () => Listen.removeListener('refresh', eventResponse);
  }, []);

  const eventResponse = (payload) => {
    // if (payload.status !== 'error') {
    setRefresh(Math.random());
    setShowModal(false);
    closeModal();
    // }
  };

  const resetState = () => {
    setValue({
      title: '',
      file: null,
      folder: 'article',
      alt: ''
    });
  };

  const closeModal = () => {
    setShowModal(false);
    resetState();
  };

  const createArticle = () => {
    let formData = new FormData();
    Array.from(values.files).map((file) => formData.append('files', file));
    // formData.append('files', values.file[0]);
    formData.append('folder', values.folder);
    formData.append('title', values.title);
    formData.append('article', article);
    formData.append('alt', values.alt);
    fetch(`${URL}/multiple-uploads`, {
      method: 'POST',
      body: formData
    })
      .then((response) => response.json())
      .then((result) => {
        const payload = {
          status: 'success',
          message: 'Banner berhasil di tambahkan'
        };
        Listen.emit('listenArticle', payload);
        Listen.emit('createToast', payload);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const handleChangeFile = (event) => {
    const selectedFiles = [];
    const targetFiles = event.target.files;
    const targetFilesObject = [...targetFiles];

    targetFilesObject.map((file) => {
      return selectedFiles.push(window.URL.createObjectURL(file));
    });
    setSelectedImages(selectedFiles);
    setValue({ ...values, files: event.target.files });
  };

  const getArticle = (skip) => {
    const body = {
      skip
    };
    dispatch(getArticleDatas(body));
  };

  const deleteArticle = (v) => {
    let text = `Apakah anda akan menghapus artikel ini?`;
    if (confirm(text) == true) {
      dispatch(removeArticle(v.id));
    } else {
      console.log('cancel delete');
    }
  };

  const getUpdateDatas = (id) => {
    const data = states.articleDatas?.data.filter((v) => v.id === id);
    setIsEdit(true);
    setID(id);
    setValue({
      ...values,
      title: data[0].title,
      alt: data[0].alt
    });
    setArticle(data[0].article);
    setShowModal(true);
  };

  const handleChange = (prop) => (event) => {
    setValue({ ...values, [prop]: event.target.value });
  };

  const updateArticle = () => {
    const body = {
      id: id,
      payload: {
        ...values,
        article
      }
    };
    dispatch(patchArticle(body));
  };

  const submitData = () => {
    isEdit ? updateArticle() : createArticle();
  };

  const renderTable = () => {
    return (
      <div className="flex flex-col ">
        <div className="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
          <div className="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">
            <table className="min-w-full">
              <thead>
                <tr>
                  <th className="w-2 px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-50">
                    No
                  </th>
                  <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-50">
                    Judul Artikel
                  </th>
                  <th className="text-xs font-medium leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-50">
                    Aksi
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {states.articleDatas &&
                  states.articleDatas.data
                    .map((v, i) => (
                      <tr key={`datas-${i}`}>
                        <td className="w-2 px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                          <div className="text-sm font-medium leading-5 text-gray-900">
                            {(i - states.articleDatas.data.length) * -1}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                          <div className="text-sm font-medium leading-5 text-gray-900">
                            {v.title}
                          </div>
                        </td>

                        <td className=" text-sm leading-5 text-gray-500 whitespace-no-wrap border-b border-gray-200">
                          <div className="flex justify-center">
                            <svg
                              onClick={() => getUpdateDatas(v.id)}
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                              className="w-5 h-5 text-blue-400 hover:cursor-pointer mr-2 mt-1"
                              // fill="none"
                              // viewBox="0 0 24 24"
                              fill="currentColor">
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1"
                                d="M29.663,482.25l.087.087a24.847,24.847,0,0,0,17.612,7.342,25.178,25.178,0,0,0,8.1-1.345l142.006-48.172,272.5-272.5A88.832,88.832,0,0,0,344.334,42.039l-272.5,272.5L23.666,456.541A24.844,24.844,0,0,0,29.663,482.25Zm337.3-417.584a56.832,56.832,0,0,1,80.371,80.373L411.5,180.873,331.127,100.5ZM99.744,331.884,308.5,123.127,388.873,203.5,180.116,412.256,58.482,453.518Z"
                              />
                            </svg>
                            <svg
                              onClick={() => deleteArticle(v)}
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-6 h-6 text-red-400 hover:cursor-pointer"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor">
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                              />
                            </svg>
                          </div>
                        </td>
                      </tr>
                    ))
                    .reverse()}
              </tbody>
            </table>
          </div>
          <Pagination datas={states.articleDatas?.total ?? 0} getDatas={(v) => getArticle(v)} />
        </div>
      </div>
    );
  };

  const _renderModal = () => {
    return (
      <Modal
        show={showModal}
        setModalClose={() => setShowModal(!showModal)}
        title={`Tambah Artikel`}
        width={'w-full'}
        onSubmit={() => submitData()}>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Judul Artikel</label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="destination"
            type="text"
            placeholder="ex. Pontianak, Sambas etc"
            value={values.title}
            onChange={handleChange('title')}
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Artikel</label>
          <ReactQuill
            theme="snow"
            value={article}
            onChange={setArticle}
            formats={formats}
            modules={modules}
          />
        </div>
        {!isEdit ? (
          <div className="flex w-full">
            <div className="mb-4 pr-2 w-1/2">
              <label className="block text-gray-700 text-sm font-bold mb-2">Feature Image</label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="file"
                name="files"
                multiple
                onChange={handleChangeFile}
              />
            </div>
            <div className="mb-4 pl-2 w-1/2">
              <label className="block text-gray-700 text-sm font-bold mb-2">alt image</label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="destination"
                type="text"
                placeholder="ex. imagepromowisata"
                value={values.alt}
                onChange={handleChange('alt')}
              />
            </div>
          </div>
        ) : (
          <></>
        )}

        <div className="grid grid-cols-4 gap-4">
          {selectedImages.length > 0 && selectedImages.map((url, id) => <img key={id} src={url} />)}
        </div>
      </Modal>
    );
  };

  const _renderBody = () => {
    return (
      <>
        <div className={`text-xl bg-white p-4 rounded-md drop-shadow-lg mt-4 flex justify-end`}>
          <Button label={'Tambah Data'} type={'primary'} cb={() => setShowModal(!showModal)} />
        </div>
        <div className={`text-xl bg-white p-4 rounded-md drop-shadow-lg mt-4`}>{renderTable()}</div>
      </>
    );
  };

  return (
    <div>
      {_renderBody()} {_renderModal()}
    </div>
  );
}
