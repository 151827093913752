import React, { useEffect, useLayoutEffect, useState } from 'react';
import cx from 'classnames';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { useMemo } from 'react';

const limitOptions = [
  { label: 10, value: 10 },
  { label: 15, value: 15 },
  { label: 20, value: 20 }
];

export const Pagination = (props) => {
  const [page, setCurrentPage] = useState(1);
  const limit = 10;

  const [pages, totalPages] = useMemo(() => {
    const total = Math.ceil(props.datas / limit) || 1;
    return [[...Array(total).fill('')], total];
  }, [props.datas]);

  const [pageNumberListLimit, setPageNumberListLimit] = useState(8);
  const [maxPageNumberListLimit, setMaxPageNumberListLimit] = useState(8);
  const [minPageNumberListLimit, setMinPageNumberListLimit] = useState(0);

  useLayoutEffect(() => {
    if (page > 1) return;
    setMaxPageNumberListLimit(8);
    setMinPageNumberListLimit(0);
  }, [page === 1, limit]);

  const renderPageNumbers = pages.map((_, index) => {
    const _page = index + 1;
    const isActive = page === _page;

    if (index < maxPageNumberListLimit && index > minPageNumberListLimit - 1) {
      return (
        <button
          key={`pagination-btn-${index}`}
          // disabled={isLoading}
          onClick={() => {
            if (!isActive) props.getDatas(index * 10);
            setCurrentPage(index + 1);
          }}
          className={cx(
            'w-8 h-8 flex items-center rounded justify-center drop-shadow font-semibold select-none text-sm',
            isActive
              ? 'bg-primary-90 text-white cursor-default bg-black'
              : 'bg-white border text-primary-90  cursor-pointer'
          )}>
          {_page}
        </button>
      );
    } else {
      return null;
    }
  });

  const handleNext = () => {
    if (page > 0) {
      props.getDatas((page + 1) * 10);
      setCurrentPage(page + 1);
    }
    if (page + 1 > maxPageNumberListLimit) {
      setMaxPageNumberListLimit(maxPageNumberListLimit + pageNumberListLimit);
      setMinPageNumberListLimit(minPageNumberListLimit + pageNumberListLimit);
    }
  };

  const handlePrev = () => {
    if (page > 0) {
      props.getDatas((page - 1) * 10);
      setCurrentPage(page - 1);
    }
    if ((page - 1) % pageNumberListLimit == 0) {
      setMaxPageNumberListLimit(minPageNumberListLimit);
      setMinPageNumberListLimit(minPageNumberListLimit - pageNumberListLimit);
    }
  };

  const toFirstPage = () => {
    props.getDatas(0 * 10);
    setCurrentPage(1);
    setMaxPageNumberListLimit(8);
    setMinPageNumberListLimit(0);
  };

  const toLastPage = () => {
    // setParam({ page: totalPages });
    props.getDatas((totalPages - 1) * 10);

    setCurrentPage(totalPages);
    setMaxPageNumberListLimit(totalPages + 1);
    setMinPageNumberListLimit(totalPages - (totalPages % pageNumberListLimit));
  };

  let pageIncrementBtn = null;
  if (pages.length > maxPageNumberListLimit) {
    pageIncrementBtn = (
      <>
        <button
          onClick={() => handleNext()}
          className={cx(
            'w-8 h-8 flex items-center  rounded justify-center drop-shadow font-semibold select-none text-sm',
            'bg-white text-primary-90  cursor-pointer'
          )}>
          &hellip;
        </button>
        <button
          onClick={() => toLastPage()}
          className={cx(
            'w-8 h-8 flex items-center rounded justify-center drop-shadow font-semibold select-none text-sm',
            'bg-white text-primary-90  cursor-pointer'
          )}>
          {totalPages}
        </button>
      </>
    );
  }

  let pageDecrementBtn = null;
  if (minPageNumberListLimit >= 1) {
    pageDecrementBtn = (
      <>
        <button
          onClick={() => toFirstPage()}
          className={cx(
            'w-8 h-8 flex items-center rounded justify-center drop-shadow font-semibold select-none text-sm',
            'bg-white text-primary-90  cursor-pointer'
          )}>
          1
        </button>
        <button
          onClick={() => handlePrev()}
          className={cx(
            'w-8 h-8 flex items-center rounded justify-center drop-shadow font-semibold select-none text-sm',
            'bg-white text-primary-90  cursor-pointer'
          )}>
          &hellip;
        </button>
      </>
    );
  }

  return (
    <>
      <div className={cx('flex justify-between mt-4 mb-4 mr-4')}>
        <div className="flex items-center w-auto space-x-4">
          <div className="block drop-shadow"></div>
        </div>
        <div className="flex items-center w-auto space-x-2">
          <button
            onClick={() => {
              if (page > 1) handlePrev();
            }}
            className={cx(
              'w-8 h-8 flex items-center rounded justify-center drop-shadow',
              page > 1
                ? 'bg-white text-black cursor-pointer'
                : 'bg-gray-50 text-gray-300 cursor-default'
            )}>
            <ChevronLeftIcon className="h-5" />
          </button>
          {pageDecrementBtn}
          {renderPageNumbers}
          {pageIncrementBtn}
          <button
            onClick={() => {
              if (page + 1 < Number(totalPages)) handleNext();
            }}
            className={cx(
              'w-8 h-8 flex items-center rounded justify-center drop-shadow',
              page + 1 < Number(totalPages)
                ? 'bg-white text-black cursor-pointer'
                : 'bg-gray-50 text-gray-300 cursor-default'
            )}>
            <ChevronRightIcon className="h-5" />
          </button>
        </div>
      </div>
    </>
  );
};

export default Pagination;

// import React, { useState, useEffect } from 'react';

// export default function index(props) {
//   const [paginationLength, setPaginationLength] = useState(Math.ceil(props.datas / 10));
//   const [currentPage, setCurrentPage] = useState(1);

//   useEffect(() => {
//     // console.log('PROPS', props);
//     setTimeout(() => {
//       setPaginationLength(Math.ceil(props.datas / 10));
//     }, 2000);
//   }, [props.datas]);

//   return (
//     <div className="flex flex-row border mt-4 justify-center items-center rounded py-2">
//       <div> </div>
//       {[...Array(paginationLength).keys()].map((v, i) => (
//         <div
//           key={i}
//           onClick={() => {
//             props.getDatas(i * 10);
//             setCurrentPage(i + 1);
//           }}
//           className={`text-base mr-3 cursor-pointer text-black ${
//             currentPage === i + 1 ? 'font-extrabold' : ''
//           }`}>
//           {i + 1}
//         </div>
//       ))}
//       {/* <div>
//         <svg xmlns="http://www.w3.org/2000/svg" height="0.7em" viewBox="0 0 448 512">
//           <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
//         </svg>
//       </div> */}
//     </div>
//   );
// }
