import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getPartnerDatas,
  getRouteDatas,
  getTicketDatas,
  postCreateTicket,
  removeTicket
} from '../../Actions/action';
import { Button, Modal, Empty, Pagination } from '../../Components';
import Listen from '../../Utils/Listen';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import id from 'date-fns/locale/id';

import { ReactSearchAutocomplete } from 'react-search-autocomplete';
// import moment from 'moment';
registerLocale('id', id);

import 'rc-time-picker/assets/index.css';
import 'react-datepicker/dist/react-datepicker.css';

export default function index() {
  const dispatch = useDispatch();
  const states = useSelector((states) => states.MainReducers);
  const [showModal, setShowModal] = React.useState(false);
  const [values, setValue] = React.useState({
    route_id: '',
    partner_id: '',
    from: '',
    to: '',
    date: '',
    time: '',
    tempId: '',
    city_id: '',
    main_from: '',
    main_to: ''
  });
  const [body, setBody] = React.useState({
    skip: 0,
    limit: 10
  });

  const [showRouteDetail, setRouteDetail] = React.useState(false);
  const [multipleValue, setMultipleValue] = React.useState([]);
  const [refresh, setRefresh] = React.useState(null);
  const [startNo, setStartNumber] = React.useState(0);

  React.useEffect(() => {
    dispatch(getPartnerDatas({ skip: 0, limit: 1000, allDatas: true }));
    dispatch(getRouteDatas());
    dispatch(getTicketDatas(body));
    console.log(states);
  }, [refresh]);

  React.useEffect(() => {
    Listen.addListener('refresh', eventResponse);
    return () => Listen.removeListener('refresh', eventResponse);
  }, []);

  const eventResponse = (payload) => {
    if (payload.status !== 'error') {
      setRefresh(Math.random());
      closeModal();
    }
  };

  const getTicket = (skip) => {
    setStartNumber(skip);
    const body = {
      skip
    };
    dispatch(getTicketDatas(body));
  };

  const pushToMultipleValue = () => {
    const id = Math.random();
    const newDatas = [...multipleValue, values];
    setValue({
      ...values,
      // date: '',
      // time: '',
      tempId: id
    });
    setMultipleValue(newDatas);
  };

  const resetState = () => {
    setValue({
      route_id: '',
      partner_id: '',
      from: '',
      to: '',
      date: '',
      time: '',
      tempId: '',
      city_id: ''
    });
    setMultipleValue([]);
  };

  const closeModal = () => {
    setShowModal(false);
    resetState();
  };

  const createTickets = () => {
    multipleValue.forEach((ticket) => dispatch(postCreateTicket(ticket)));
    resetState();
  };

  const deleteTicket = (v) => {
    let text = `Apakah anda akan menghapus ${v.name} dari daftar destinasi?`;
    if (confirm(text) == true) {
      dispatch(removeTicket(v.id));
    }
  };

  const handleChange = (prop) => (event) => {
    setValue({ ...values, [prop]: event.target.value });
  };

  const filterArr = (arr, key, filterPar) => {
    const a = arr.filter((v) => v[key] == filterPar);
    return a[0];
  };

  const deleteArr = (arr, key, filterPar) => {
    setMultipleValue((current) => current.filter((ticket) => ticket[key] !== filterPar));
    // return arr.filter((v) => v[key] === filterPar);
  };

  const handleOnSelectRoute = (prop) => (item) => {
    const datas = states.routeDatas.data.filter((v) => v.id === item.id);
    setValue({
      ...values,
      [prop]: item.id,
      from: datas[0].from,
      to: datas[0].to,
      city_id: datas[0].city_id,
      main_from: datas[0].main_from,
      main_to: datas[0].main_to
    });
    setRouteDetail(true);
  };

  const handleOnSelect = (prop) => (item) => {
    setValue({ ...values, [prop]: item.id });
  };

  const formatResult = (item) => {
    return (
      <>
        <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
      </>
    );
  };

  const renderTable = () => {
    return (
      <div className="flex flex-col ">
        <div className="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
          <div className="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">
            <table className="min-w-full">
              <thead>
                <tr>
                  <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-50">
                    No
                  </th>
                  <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-50">
                    Keberangkatan
                  </th>
                  <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-50">
                    Tujuan
                  </th>
                  <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-50">
                    Waktu
                  </th>

                  <th className="text-xs font-medium leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-50">
                    Hapus
                  </th>
                </tr>
              </thead>
              {states.ticketDatas ? (
                <tbody className="bg-white">
                  {states.ticketDatas?.data.map((v, i) => (
                    <tr key={`datas-${i}`}>
                      <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                        <div className="text-sm font-medium leading-5 text-gray-900">
                          {startNo + i + 1}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                        <div className="text-sm font-medium leading-5 text-gray-900">
                          {v.main_from} ({v.from})
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                        <div className="text-sm font-medium leading-5 text-gray-900">
                          {' '}
                          {v.main_to} ({v.to})
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                        <div className="text-sm font-medium leading-5 text-gray-900">
                          {v.date} / {v.time}
                        </div>
                      </td>
                      <td className=" text-sm leading-5 text-gray-500 whitespace-no-wrap border-b border-gray-200">
                        <svg
                          onClick={() => deleteTicket(v)}
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-6 h-6 text-red-400 hover:cursor-pointer"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor">
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                          />
                        </svg>
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <div className="w-full">
                      <Empty />
                    </div>
                  </tr>
                </tbody>
              )}
            </table>

            <Pagination datas={states.ticketDatas?.total ?? 0} getDatas={(v) => getTicket(v)} />
          </div>
        </div>
      </div>
    );
  };

  const renderTableInModal = () => {
    return (
      <div className="flex flex-col ">
        <div className="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
          <div className="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">
            <table className="min-w-full">
              <thead>
                <tr>
                  <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-50">
                    No
                  </th>
                  <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-50">
                    From
                  </th>
                  <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-50">
                    To
                  </th>
                  <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-50">
                    Tanggal
                  </th>
                  <th className="text-xs text-center font-medium leading-4 tracking-wider  text-black uppercase border-b border-gray-200 bg-gray-50">
                    Jam
                  </th>
                  <th className="text-xs text-center font-medium leading-4 tracking-wider  text-black uppercase border-b border-gray-200 bg-gray-50">
                    Penyedia
                  </th>
                  <th className="text-xs font-medium leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-50">
                    Hapus
                  </th>
                </tr>
              </thead>
              {multipleValue ? (
                <tbody className="bg-white">
                  {multipleValue.map((v, i) => (
                    <tr key={`datas-${i}`}>
                      <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                        <div className="text-sm font-medium leading-5 text-gray-900">{i + 1}</div>
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                        <div className="text-sm font-medium leading-5 text-gray-900">{v.from}</div>
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                        <div className="text-sm font-medium leading-5 text-gray-900">{v.to}</div>
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                        <div className="text-sm font-medium leading-5 text-gray-900">{v.date}</div>
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                        <div className="text-sm font-medium leading-5 text-gray-900">{v.time}</div>
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                        <div className="text-sm font-medium leading-5 text-gray-900">
                          {(() => {
                            const val = filterArr(states.partnerDatas.data, 'id', v.partner_id);
                            return val.name;
                          })()}
                        </div>
                      </td>
                      <td className=" text-sm leading-5 text-gray-500 whitespace-no-wrap border-b border-gray-200">
                        <svg
                          onClick={() => deleteArr(multipleValue, 'tempId', v.tempId)}
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-6 h-6 text-red-400 hover:cursor-pointer"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor">
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                          />
                        </svg>
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <div className="w-full">
                      <Empty />
                    </div>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>
    );
  };

  const onChangeDate = (e) => {
    const newDate = moment(new Date(e.target.value)).format('DD/MM/YYYY');
    setValue({ ...values, date: newDate });
  };

  const onChangeTime = (e) => {
    setValue({ ...values, time: e.target.value });
  };

  const _renderModal = () => {
    return (
      <Modal
        show={showModal}
        setModalClose={() => {
          setShowModal(!showModal);
          setRouteDetail(false);
        }}
        title={`Buat Tiket`}
        width={'w-full'}
        onSubmit={() => createTickets()}>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Rute Perjalanan</label>
          <ReactSearchAutocomplete
            items={states.routeDatas?.data}
            onSelect={handleOnSelectRoute('route_id')}
            formatResult={formatResult}
            placeholder={'Pilih Kota Keberangkatan'}
            styling={{ zIndex: 2 }}
          />
        </div>
        {showRouteDetail && (
          <div className="p-4 bg-orange-300 border-orange-500 rounded flex flex-col">
            <span className="text-base font-bold">
              {values.main_from} - {values.main_to}
            </span>
            <span className="text-base font-semibold">Berangkat dari : {values.from}</span>
            <span className="text-base font-semibold">Berhenti di : {values.to}</span>
          </div>
        )}
        <div className="flex">
          <div className="w-1/3 p-2">
            <label className="block text-gray-700 text-sm font-bold mb-2">Tanggal</label>

            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="date"
              placeholder="ex. MCC tower 12"
              onChange={onChangeDate}
            />
          </div>
          <div className="w-1/3 p-2">
            <label className="block text-gray-700 text-sm font-bold mb-2">Jam</label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="time"
              placeholder="ex. MCC tower 12"
              onChange={onChangeTime}
            />
          </div>
          <div className="w-1/3 p-2">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Penyedia Perjalanan
            </label>
            <ReactSearchAutocomplete
              items={states.partnerDatas?.data ?? []}
              onSelect={handleOnSelect('partner_id')}
              formatResult={formatResult}
              placeholder={'Pilih Partner'}
              styling={{ zIndex: 1 }}
            />
          </div>
        </div>
        <div className="w-full bg-green-50 mb-4 px-2">
          <Button
            label={'Simpan Jadwal'}
            type={'oprimary'}
            cb={pushToMultipleValue}
            extraClass={'w-full'}
          />
        </div>
        {renderTableInModal()}
      </Modal>
    );
  };

  const _renderBody = () => {
    return (
      <>
        <div className={`text-xl bg-white p-4 rounded-md drop-shadow-lg mt-4 flex justify-end`}>
          <Button label={'Buat Tiket'} type={'primary'} cb={() => setShowModal(!showModal)} />
        </div>
        <div className={`text-xl bg-white p-4 rounded-md drop-shadow-lg mt-4`}>{renderTable()}</div>
      </>
    );
  };

  return (
    <div>
      {_renderBody()} {_renderModal()}
    </div>
  );
}
