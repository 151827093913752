import {
  SET_LOGIN,
  POST_ADMINDATAS,
  POST_CITYDATAS,
  POST_ROUTEDATAS,
  POST_PARTNERDATAS,
  POST_VEHILCEDATAS,
  POST_TICKETDATAS,
  POST_BANNERDATAS,
  POST_ARTICLEDATAS,
  POST_CONFIGDATAS,
  POST_ALL_PARTNERDATAS
} from '../Actions/type';

const INIT_STATE = {
  isLogin: false,
  credential: null,
  adminDatas: null,
  cityDesinationDatas: null,
  routeDatas: null,
  partnerDatas: null,
  partnerAllDatas: null,
  vehicleDatas: null,
  ticketDatas: null,
  bannerDatas: null,
  articleDatas: null,
  configDatas: null
};

export default (state, action) => {
  if (typeof state === 'undefined') {
    return INIT_STATE;
  }
  // eslint-disable-next-line default-case
  switch (action.type) {
    case SET_LOGIN: {
      return {
        ...state,
        credential: action.payload
      };
    }
    case POST_ADMINDATAS: {
      return {
        ...state,
        adminDatas: action.payload
      };
    }
    case POST_CITYDATAS: {
      return {
        ...state,
        cityDesinationDatas: action.payload
      };
    }
    case POST_ROUTEDATAS: {
      return {
        ...state,
        routeDatas: action.payload
      };
    }
    case POST_PARTNERDATAS: {
      return {
        ...state,
        partnerDatas: action.payload
      };
    }
    case POST_ALL_PARTNERDATAS: {
      return {
        ...state,
        partnerAllDatas: action.payload
      };
    }
    case POST_VEHILCEDATAS: {
      return {
        ...state,
        vehicleDatas: action.payload
      };
    }
    case POST_TICKETDATAS: {
      return {
        ...state,
        ticketDatas: action.payload
      };
    }
    case POST_BANNERDATAS: {
      return {
        ...state,
        bannerDatas: action.payload
      };
    }
    case POST_ARTICLEDATAS: {
      return {
        ...state,
        articleDatas: action.payload
      };
    }
    case POST_CONFIGDATAS: {
      return {
        ...state,
        configDatas: action.payload
      };
    }
    default:
      return state;
  }
};
