// Administrator
export const ADMINISTRATOR = '/users';
export const CITIES_DESTINATION = '/cities-destination';
export const ROUTES_TRIP = '/routes-trip';
export const PARTNERS = '/partner';
export const VEHICLES = '/vehicle-details';
export const TICKET = '/ticket';
export const UPLOAD_FILE = '/file-upload';
export const BANNER = '/banner';
export const ARTICLE = '/article';
export const CONFIGURATION = '/configuration';
export const AUTHENTICATION = '/authentication';
