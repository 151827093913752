import { del, get, patch, post, update } from './config';

import * as url from './url';

export const postLogin = (payload) => post(url.AUTHENTICATION, payload);

export const createAdmin = (payload) => post(url.ADMINISTRATOR, payload);
export const getAllAdmin = () => get(url.ADMINISTRATOR);
export const deleteAdmin = (payload) => del(url.ADMINISTRATOR, payload);

export const createCityDestination = (payload) => post(url.CITIES_DESTINATION, payload);
export const getAllCityDestination = (payload) =>
  get(`${url.CITIES_DESTINATION}?$skip=${payload.skip}`);
export const deleteCityDestination = (payload) => del(url.CITIES_DESTINATION, payload);

export const createRoute = (payload) => post(url.ROUTES_TRIP, payload);
export const updateRoute = (payload) => update(url.ROUTES_TRIP, payload);
export const getAllRoute = (payload) => get(`${url.ROUTES_TRIP}?$skip=${payload.skip}`);
export const deleteRoute = (payload) => del(url.ROUTES_TRIP, payload);

export const createPartner = (payload) => post(url.PARTNERS, payload);
export const getAllPartner = (payload) =>
  get(`${url.PARTNERS}?$skip=${payload.skip}&$limit=${payload.limit}`);
export const deletePartner = (payload) => del(url.PARTNERS, payload);

export const createVehicle = (payload) => post(url.VEHICLES, payload);
export const getAllVehicle = (payload) => get(`${url.VEHICLES}?$skip=${payload.skip}`);
export const patchVehicle = (payload) => patch(url.VEHICLES, payload);
export const deleteVehicle = (payload) => del(url.VEHICLES, payload);

export const createTicket = (payload) => post(url.TICKET, payload);
export const getAllTicket = (payload) => get(`${url.TICKET}?$skip=${payload.skip}`);
export const deleteTicket = (payload) => del(url.TICKET, payload);

export const getAllBanner = () => get(url.BANNER);
export const patcBanner = (payload) => patch(url.BANNER, payload);
export const deleteBanner = (payload) => del(url.BANNER, payload);

export const getAllArticle = (payload) => get(`${url.ARTICLE}?$skip=${payload.skip}`);
export const patchArticle = (payload) => patch(url.ARTICLE, payload);
export const deleteArticle = (payload) => del(url.ARTICLE, payload);

export const uploadFile = (payload) => post(url.UPLOAD_FILE, payload);

export const createConfig = (payload) => post(url.CONFIGURATION, payload);
export const getAllConfig = () => get(url.CONFIGURATION);
export const patchConfig = (payload) => patch(url.CONFIGURATION, payload);
export const deleteConfig = (payload) => del(url.CONFIGURATION, payload);
// export const getAllTicket = () => get(url.TICKET);
// export const deleteTicket = (payload) => del(url.TICKET, payload);
