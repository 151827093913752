import React from 'react';

const types = [
  {
    type: 'primary',
    style:
      'py-2 px-3 text-sm font-medium text-center text-white rounded-lg bg-blue-700  hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'
  },
  {
    type: 'oprimary',
    style:
      'py-2 px-3 text-sm font-medium text-center text-white rounded-lg bg-green-700  hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'
  },
  {
    type: 'danger',
    style:
      'py-2 px-3 text-sm font-medium text-center text-white rounded-lg bg-red-700  hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800'
  }
];

export default function index(props) {
  const { label, type, icon, disable, extraClass, cb } = props;

  const [style, setStyle] = React.useState({
    type: null
  });

  React.useEffect(() => {
    getType();
  }, []);

  const getType = () => {
    const bttype = types.filter((v) => v.type === type);
    setStyle({ ...style, type: bttype[0].style });
  };

  return (
    <button
      onClick={cb}
      type="button"
      className={`${extraClass} ${style.size} ${style.type}`}
      disabled={disable}>
      {icon}
      {label}
    </button>
  );
}
