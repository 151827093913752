import React from 'react';
import { Button, Modal } from '../../Components';
import { useDispatch, useSelector } from 'react-redux';
import { getAdminDatas, postCreateAdmin, removeAdmin } from '../../Actions/action';
import Listen from '../../Utils/Listen';

export default function index() {
  const dispatch = useDispatch();
  const states = useSelector((states) => states.MainReducers);

  const [showModal, setShowModal] = React.useState(false);
  const [values, setValue] = React.useState({
    username: '',
    email: '',
    wa_number: '',
    password: ''
  });

  React.useEffect(() => {
    Listen.addListener('createAdminResponse', eventResponse);
    return () => Listen.removeListener('createAdminResponse', eventResponse);
  }, []);

  React.useEffect(() => {
    dispatch(getAdminDatas());
  }, [states.adminDatas]);

  const eventResponse = (payload) => {
    if (payload.status !== 'error') {
      closeModal();
    }
  };

  const resetState = () => {
    setValue({
      username: '',
      email: '',
      wa_number: '',
      password: ''
    });
  };

  const handleChange = (prop) => (event) => {
    setValue({ ...values, [prop]: event.target.value });
  };

  const createAdmin = () => {
    dispatch(postCreateAdmin(values));
  };

  const closeModal = () => {
    setShowModal(!showModal);
    resetState();
  };

  const deleteAdmin = (v) => {
    let text = `Apakah anda akan menghapus ${v.username} dari Administrator?`;
    if (confirm(text) == true) {
      dispatch(removeAdmin(v.id));
    } else {
      console.log('cancel delete');
    }
  };

  const renderTable = () => {
    return (
      <div className="flex flex-col ">
        <div className="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
          <div className="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">
            <table className="min-w-full">
              <thead>
                <tr>
                  <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-50">
                    No
                  </th>
                  <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-50">
                    Username
                  </th>
                  <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-50">
                    Email
                  </th>
                  <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-50">
                    Whats App
                  </th>
                  <th className="text-xs font-medium leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-50">
                    Hapus
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {states.adminDatas &&
                  states.adminDatas.map((v, i) => (
                    <tr key={`datas-${i}`}>
                      <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                        <div className="text-sm font-medium leading-5 text-gray-900">{i + 1}</div>
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                        <div className="text-sm font-medium leading-5 text-gray-900">
                          {v.username}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                        <div className="text-sm font-medium leading-5 text-gray-900">{v.email}</div>
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                        <div className="text-sm font-medium leading-5 text-gray-900">
                          {v.wa_number}
                        </div>
                      </td>
                      <td className=" text-sm leading-5 text-gray-500 whitespace-no-wrap border-b border-gray-200">
                        <svg
                          onClick={() => deleteAdmin(v)}
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-6 h-6 text-red-400 hover:cursor-pointer"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor">
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                          />
                        </svg>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };

  const _renderModal = () => {
    return (
      <Modal
        show={showModal}
        setModalClose={() => setShowModal(!showModal)}
        title={`Tambah Admin`}
        onSubmit={() => createAdmin()}>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Username</label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="username"
            type="text"
            placeholder="Masukkan username"
            value={values.username}
            onChange={handleChange('username')}
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Email</label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="username"
            type="text"
            placeholder="Masukkan email"
            value={values.email}
            onChange={handleChange('email')}
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">No Whatapp</label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="username"
            type="text"
            placeholder="Masukkan no whatsapp"
            value={values.wa_number}
            onChange={handleChange('wa_number')}
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Password</label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="password"
            type="password"
            placeholder="******************"
            value={values.password}
            onChange={handleChange('password')}
          />
        </div>
      </Modal>
    );
  };

  const _renderBody = () => {
    return (
      <>
        <div className={`text-xl bg-white p-4 rounded-md drop-shadow-lg mt-4 flex justify-end`}>
          <Button label={'Tambah Data'} type={'primary'} cb={closeModal} />
        </div>
        <div className={`text-xl bg-white p-4 rounded-md drop-shadow-lg mt-4`}>{renderTable()}</div>
      </>
    );
  };

  return (
    <div>
      {_renderBody()} {_renderModal()}
    </div>
  );
}
