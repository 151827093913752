import React from 'react';

export default function index() {
  return (
    <div className="w-full flex flex-col justify-center items-center py-10">
      <span className="text-4xl font-black">Oops!</span>
      <span className="text-sm">Data tidak ditemukan</span>
    </div>
  );
}
