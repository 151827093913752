import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getVehicleDatas,
  getPartnerDatas,
  postCreateVehicle,
  removeVehicle,
  patchVehicle
} from '../../Actions/action';
import { Button, Modal, Switch, Pagination } from '../../Components';
import Listen from '../../Utils/Listen';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';

const URL = 'https://borneotrans-api.amazingborneo.id';
// const URL = 'http://localhost:3030/'

export default function index() {
  const dispatch = useDispatch();
  const states = useSelector((states) => states.MainReducers);
  const [showModal, setShowModal] = React.useState(false);
  const [values, setValue] = React.useState({
    name: '',
    folder: 'vehicles',
    brand: '',
    cc: '',
    year: '',
    own_driving: false,
    price_per_day: '',
    thumbnail: null,
    partner_id: false,
    available: false,
    type: 'minibus',
    seats: '',
    files: null,
    category: '',
    maintype: ''
  });
  const [refresh, setRefresh] = React.useState(null);
  const [body, setBody] = React.useState({
    skip: 0,
    limit: 10
  });
  const [startNo, setStartNumber] = React.useState(0);

  React.useEffect(() => {
    dispatch(getVehicleDatas(body));
    dispatch(
      getPartnerDatas({
        skip: 0,
        limit: 100
      })
    );
  }, [refresh]);

  React.useEffect(() => {
    Listen.addListener('refresh', eventResponse);
    return () => Listen.removeListener('refresh', eventResponse);
  }, []);

  const eventResponse = (payload) => {
    if (payload.status !== 'error') {
      setRefresh(Math.random());
      closeModal();
    }
  };

  const resetState = () => {
    setValue({
      name: '',
      files: null,
      folder: 'vehicles',
      brand: '',
      cc: '',
      year: '',
      own_driving: false,
      price_per_day: '',
      thumbnail: null,
      partner_id: false,
      available: '',
      type: '',
      seats: '',
      category: '',
      maintype: ''
    });
  };

  const filterArr = (arr, key, filterPar) => {
    const a = arr ? arr?.filter((v) => v[key] == filterPar) : [];
    return a[0] ?? [];
  };

  const getVehicles = (skip) => {
    setStartNumber(skip);
    const body = {
      skip
    };
    dispatch(getVehicleDatas(body));
  };

  const setAvailability = (vehicle) => {
    const body = {
      id: vehicle.id,
      payload: {
        available: !vehicle.available
      }
    };
    dispatch(patchVehicle(body));
  };

  const closeModal = () => {
    setShowModal(false);
    resetState();
  };

  const createVehicle = () => {
    let formData = new FormData();

    Array.from(values.files).map((file) => formData.append('files', file));
    // values.files.forEach((file) => {
    //   formData.append('files', file.files[0]);
    // });
    formData.append('name', values.name);
    formData.append('brand', values.brand);
    formData.append('cc', values.cc);
    formData.append('year', values.year);
    formData.append('own_driving', values.own_driving);
    formData.append('price_per_day', values.price_per_day);
    formData.append('partner_id', values.partner_id);
    formData.append('available', values.available);
    formData.append('type', values.type);
    formData.append('seats', values.seats);
    formData.append('category', values.category);
    formData.append('maintype', values.maintype);
    formData.append('folder', values.folder);

    fetch(`${URL}/multiple-uploads`, {
      method: 'POST',
      body: formData
    })
      .then((response) => response.json())
      .then((result) => {
        const payload = {
          status: 'success',
          message: 'Banner berhasil di tambahkan'
        };
        Listen.emit('refresh', payload);
        Listen.emit('createToast', payload);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  // const createVehicle = () => {
  //   dispatch(postCreateVehicle(values));
  // };

  const deleteVehicle = (v) => {
    let text = `Apakah anda akan menghapus ${v.name} dari daftar destinasi?`;
    if (confirm(text) == true) {
      dispatch(removeVehicle(v.id));
    } else {
      console.log('cancel delete');
    }
  };

  const handleChange = (prop) => (event) => {
    setValue({ ...values, [prop]: event.target.value });
  };

  const getVehicle = (skip) => {
    const body = {
      skip
    };
    dispatch(getVehicleDatas(body));
  };

  const renderTable = () => {
    return (
      <div className="flex flex-col ">
        <div className="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
          <div className="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">
            <table className="min-w-full">
              <thead>
                <tr>
                  <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-50">
                    No
                  </th>
                  <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-50">
                    Partner
                  </th>
                  <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-50">
                    Merk
                  </th>
                  <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-50">
                    Brand
                  </th>
                  <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-50">
                    Kap. Mesin
                  </th>
                  <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-50">
                    Tahun
                  </th>
                  <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-50">
                    Lepas Kunci
                  </th>
                  <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-50">
                    Harga / hari
                  </th>
                  <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-50">
                    Tersedia
                  </th>
                  <th className="text-xs font-medium leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-50">
                    Hapus
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {states.vehicleDatas &&
                  states.vehicleDatas?.data.map((v, i) => (
                    <tr key={`datas-${i}`}>
                      {/* <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                        <div className="text-sm font-medium leading-5 text-gray-900">{i + 1}</div>
                      </td> */}
                      <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                        <div className="text-sm font-medium leading-5 text-gray-900">
                          {startNo + i + 1}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                        <div className="text-sm font-medium leading-5 text-gray-900">
                          {/* {v.partner_id} */}
                          {(() => {
                            const val = filterArr(
                              states.partnerDatas?.data ?? [],
                              'id',
                              v.partner_id
                            );
                            return val.name;
                          })()}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                        <div className="text-sm font-medium leading-5 text-gray-900">{v.name}</div>
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                        <div className="text-sm font-medium leading-5 text-gray-900">{v.brand}</div>
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                        <div className="text-sm font-medium leading-5 text-gray-900">{v.cc}</div>
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                        <div className="text-sm font-medium leading-5 text-gray-900">{v.year}</div>
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                        <div className="text-sm font-medium leading-5 text-gray-900">
                          {v.own_driving ? 'YA' : 'TIDAK'}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                        <div className="text-sm font-medium leading-5 text-gray-900">
                          {v.price_per_day}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                        <div className="text-sm font-medium leading-5 text-gray-900">
                          <Switch
                            checked={v.available}
                            onSwitch={() => setAvailability(v)}></Switch>
                          {}
                        </div>
                      </td>
                      <td className=" text-sm leading-5 text-gray-500 whitespace-no-wrap border-b border-gray-200">
                        <svg
                          onClick={() => deleteVehicle(v)}
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-6 h-6 text-red-400 hover:cursor-pointer"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor">
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                          />
                        </svg>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <Pagination datas={states.vehicleDatas?.total ?? 0} getDatas={(v) => getVehicles(v)} />
          </div>
        </div>
      </div>
    );
  };

  const handleOnSelect = (prop) => (item) => {
    setValue({ ...values, [prop]: item.id });
  };

  const handleChangeFile = (event) => {
    setValue({ ...values, files: event.target.files });
  };

  const formatResult = (item) => {
    return (
      <>
        <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
      </>
    );
  };

  const _renderModal = () => {
    return (
      <Modal
        show={showModal}
        setModalClose={() => setShowModal(!showModal)}
        title={`Tambah Kendaraan Rendal`}
        onSubmit={() => createVehicle()}
        width={'w-1/2'}>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Merk</label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="destination"
            type="text"
            placeholder="ex. Avanza 2022, Innova"
            value={values.name}
            onChange={handleChange('name')}
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Brand</label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="destination"
            type="text"
            placeholder="ex. Mistsubishi, Toyota"
            value={values.brand}
            onChange={handleChange('brand')}
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Kapasitas Mesin</label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="destination"
            type="text"
            placeholder="ex. 1.500"
            value={values.cc}
            onChange={handleChange('cc')}
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Tahun</label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="destination"
            type="text"
            placeholder="ex. 2021"
            value={values.year}
            onChange={handleChange('year')}
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Lepas Kunci</label>
          <Switch
            checked={values.own_driving}
            onSwitch={() => setValue({ ...values, own_driving: !values.own_driving })}
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Harga per hari (24 jam)
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="destination"
            type="text"
            placeholder="ex. 200.000"
            value={values.price_per_day}
            onChange={handleChange('price_per_day')}
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Partner</label>
          <ReactSearchAutocomplete
            items={states.partnerDatas}
            onSelect={handleOnSelect('partner_id')}
            formatResult={formatResult}
            placeholder={'Pilih Partner'}
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Tipe Kendaraan</label>
          <select
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            onChange={handleChange('maintype')}>
            <option value={'Bus'}>Bus</option>
            <option value={'Mini Bus'}>Mini Bus</option>
          </select>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Kategori Kendaraan</label>
          <select
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            onChange={handleChange('category')}>
            <option value={'Mini Bus'}>Mini Bus</option>
            <option value={'Van'}>Van</option>
            <option value={'Medium Bus'}>Meduim Bus</option>
            <option value={'Big Bus'}>Big Bus</option>
            <option value={'Executive'}>Executive</option>
          </select>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Jumlah Kursi</label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="destination"
            type="text"
            placeholder="ex. 200.000"
            value={values.seats}
            onChange={handleChange('seats')}
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Banner</label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="file"
            name="files"
            accept="image/*"
            onChange={handleChangeFile}
            multiple
          />
        </div>
      </Modal>
    );
  };

  const _renderBody = () => {
    return (
      <>
        <div className={`text-xl bg-white p-4 rounded-md drop-shadow-lg mt-4 flex justify-end`}>
          <Button label={'Tambah Data'} type={'primary'} cb={() => setShowModal(!showModal)} />
        </div>
        <div className={`text-xl bg-white p-4 rounded-md drop-shadow-lg mt-4`}>{renderTable()}</div>
      </>
    );
  };

  return (
    <div>
      {_renderBody()} {_renderModal()}
    </div>
  );
}
