import React from 'react';
import { Route, NavLink, HashRouter } from 'react-router-dom';
import { sideBarMenu } from '../Helpers/SidebarMenus';
import { useSelector } from 'react-redux';
import { Navbar } from '../Components';
import { ToastContainer, toast } from 'react-toastify';
import LoginPage from './../Pages/Login';
import Listen from '../Utils/Listen';
import 'react-toastify/dist/ReactToastify.css';
import { useCookies, Cookies } from 'react-cookie';
import { useDispatch } from 'react-redux';
import { setLogin } from '../Actions/action';

export default function Root() {
  const dispatch = useDispatch();
  const states = useSelector((states) => states.MainReducers);
  const [cookies, setCookie, removeCookie] = useCookies(['accessToken']);
  const [isLogin, setIsLogin] = React.useState(false);

  //Listener for toast
  React.useEffect(() => {
    Listen.addListener('createToast', createAlert);

    return () => Listen.removeListener('createToast', createAlert);
  }, []);

  React.useEffect(() => {
    if (!cookies.accessToken) {
      setIsLogin(false);
      dispatch(setLogin(null));
    } else {
      setIsLogin(true);
    }
  }, []);

  React.useEffect(() => {
    if (states.credential !== null) {
      setIsLogin(true);
      setCookie('accessToken', states.credential.accessToken, { maxAge: 7200 });
    }
  }, [states.credential]);

  const createAlert = (payload) => {
    if (payload.status === 'error') {
      toast.error(payload.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
    } else {
      toast.success(payload.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
    }
  };

  const [activeMenu, setActiveMenu] = React.useState({
    id: null,
    label: null,
    url: null,
    icon: null
  });

  const logout = () => {
    removeCookie('accessToken');
    setIsLogin(false);
    window.location = '/';
  };

  return (
    <HashRouter>
      {isLogin ? (
        <div className={`h-screen  flex overflow-x-hidden`}>
          <ToastContainer />
          {/* <img src={require('./assets/waves.png')} className={`w-full absolute bottom-0 z-0 opacity-10`} /> */}
          <div className={`fixed w-200 h-full bg-black shadow-default py-5 px-4`}>
            <ul>
              {sideBarMenu.map((v, i) => (
                <li
                  key={i}
                  className={`mt-2`}
                  onClick={() => {
                    setActiveMenu({ ...v });
                  }}>
                  <NavLink
                    to={v.url}
                    className={`py-3 px-4 font-bold rounded-md cursor-pointer items-center text-md text-white text-sm flex hover:bg-white hover:text-black ${
                      v.id === activeMenu.id ? `bg-white text-black  font-bold` : ``
                    } `}>
                    <i className={`bx ${v.icon} bx-sm mr-4`}></i>
                    {v.label}
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
          <div className={`w-full py-5 px-4 bg-gray-100 overflow-y-auto`}>
            <div className="w-4/5 ml-[220px] justify-end items-end">
              <Navbar>
                <h1 className={'font-extrabold text-2xl'}>
                  {activeMenu.label ?? `Selamat Datang`}
                </h1>
                <h3 onClick={() => logout()}>Logout</h3>
              </Navbar>
              {sideBarMenu.map((v, i) => (
                <Route key={i} path={v.url} component={v.page} />
              ))}
            </div>
          </div>
        </div>
      ) : (
        <Route exact path="/">
          <LoginPage />
        </Route>
      )}
    </HashRouter>
  );
}
