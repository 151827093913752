const POST_LOGIN = 'POST_LOGIN';
const SET_LOGIN = 'SET_LOGIN';

const POST_CREATEADMIN = 'POST_CREATEADMIN';
const GET_ADMINDATAS = 'GET_ADMIN';
const POST_ADMINDATAS = 'POST_ADMINDATAS';
const DELETE_ADMIN = 'DELETE_ADMIN';

const POST_CREATECITY = 'POST_CREATECITY';
const GET_CITYDATAS = 'GET_CITYDATAS';
const POST_CITYDATAS = 'POST_CITYDATAS';
const DELETE_CITY = 'DELETE_CITY';

const POST_CREATEROUTES = 'POST_CREATEROUTES';
const GET_ROUTEDATAS = 'GET_ROUTEDATAS';
const UPDATE_ROUTE = 'UPDATE_ROUTE';
const POST_ROUTEDATAS = 'POST_ROUTEDATAS';
const DELETE_ROUTE = 'DELETE_ROUTE';

const POST_CREATEPARTNERS = 'POST_CREATEPARTNERS';
const GET_PARTNERDATAS = 'GET_PARTNERS';
const POST_PARTNERDATAS = 'POST_PARTNERDATAS';
const POST_ALL_PARTNERDATAS = 'POST_ALL_PARTNER_DATAS';
const DELETE_PARTNER = 'DELETE_PARTNER';

const POST_CREATEVEHICLE = 'POST_CREATEVEHICLE';
const GET_VEHICLERDATAS = 'GET_VEHICLERDATAS';
const POST_VEHILCEDATAS = 'POST_VEHILCEDATAS';
const PATCH_VEHICLE = 'PATCH_VEHICLE';
const DELETE_VEHICLE = 'DELETE_VEHICLE';

const POST_CREATETICKET = 'POST_CREATETICKET';
const GET_TICKETRDATAS = 'GET_TICKETRDATAS';
const POST_TICKETDATAS = 'POST_TICKETDATAS';
const DELETE_TICKET = 'DELETE_TICKET';

const POST_CREATEBANNER = 'POST_CREATEBANNER';
const GET_BANNERDATAS = 'GET_BANNERDATAS';
const POST_BANNERDATAS = 'POST_BANNERDATAS';
const PATCH_BANNER = 'PATCH_BANNER';
const DELETE_BANNER = 'DELETE_BANNER';

const GET_ARTICLEDATAS = 'GET_ARTICLEDATAS';
const POST_ARTICLEDATAS = 'POST_ARTICLEDATAS';
const PATCH_ARTICLE = 'PATCH_ARTICLE';
const DELETE_ARTICLE = 'DELETE_ARTILCE';

const POST_UPLOADFILE = 'POST_UPLOADFILE';

const POST_CREATECONFIG = 'POST_CREATECONFIG';
const GET_CONFIGDATAS = 'GET_CONFIGDATAS';
const POST_CONFIGDATAS = 'POST_CONFIGDATAS';
const PATCH_CONFIG = 'PATCH_CONFIG';
const DELETE_CONFIG = 'DELETE_CONFIG';

export {
  POST_LOGIN,
  SET_LOGIN,
  POST_CREATEADMIN,
  GET_ADMINDATAS,
  POST_ADMINDATAS,
  DELETE_ADMIN,
  POST_CREATECITY,
  GET_CITYDATAS,
  POST_CITYDATAS,
  DELETE_CITY,
  POST_CREATEROUTES,
  UPDATE_ROUTE,
  GET_ROUTEDATAS,
  POST_ROUTEDATAS,
  DELETE_ROUTE,
  POST_CREATEPARTNERS,
  GET_PARTNERDATAS,
  POST_PARTNERDATAS,
  POST_ALL_PARTNERDATAS,
  DELETE_PARTNER,
  POST_CREATEVEHICLE,
  GET_VEHICLERDATAS,
  POST_VEHILCEDATAS,
  PATCH_VEHICLE,
  DELETE_VEHICLE,
  POST_CREATETICKET,
  GET_TICKETRDATAS,
  POST_TICKETDATAS,
  DELETE_TICKET,
  POST_CREATEBANNER,
  GET_BANNERDATAS,
  POST_BANNERDATAS,
  DELETE_BANNER,
  POST_UPLOADFILE,
  PATCH_BANNER,
  GET_ARTICLEDATAS,
  POST_ARTICLEDATAS,
  PATCH_ARTICLE,
  DELETE_ARTICLE,
  POST_CREATECONFIG,
  POST_CONFIGDATAS,
  GET_CONFIGDATAS,
  PATCH_CONFIG,
  DELETE_CONFIG
};
