import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCityDatas,
  getRouteDatas,
  postCreateRoute,
  removeRoute,
  updateRoute
} from '../../Actions/action';
import { Button, Modal, Pagination } from '../../Components';
import Listen from '../../Utils/Listen';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { thousandSeparator } from '../../Utils/Regex';

export default function index() {
  const dispatch = useDispatch();
  const states = useSelector((states) => states.MainReducers);
  const [showModal, setShowModal] = React.useState(null);
  const [values, setValue] = React.useState({
    name: '',
    city_id: '',
    from: '',
    to: '',
    price: '',
    time_estimate: ''
  });
  const [isEdit, setIsEdit] = React.useState(false);
  const [id, setID] = React.useState(null);
  const [refresh, setRefresh] = React.useState(null);
  const [body, setBody] = React.useState({
    skip: 0,
    limit: 10
  });
  const [startNo, setStartNumber] = React.useState(0);

  React.useEffect(() => {
    dispatch(getCityDatas());
    dispatch(getRouteDatas(body));
  }, [refresh]);

  React.useEffect(() => {
    Listen.addListener('refresh', eventResponse);
    return () => Listen.removeListener('refresh', eventResponse);
  }, []);

  const eventResponse = (payload) => {
    if (payload.status !== 'error') {
      setRefresh(Math.random());
      closeModal();
    }
  };

  const resetState = () => {
    setValue({
      name: '',
      city_id: '',
      main_from: '',
      main_to: '',
      from: '',
      to: '',
      price: '',
      time_estimate: ''
    });
  };

  const closeModal = () => {
    setShowModal(false);
    resetState();
  };

  const createRoute = () => {
    dispatch(postCreateRoute(values));
  };

  const updateRoutes = () => {
    const body = {
      id: id,
      payload: {
        ...values
      }
    };
    dispatch(updateRoute(body));
  };

  const handleOnSelect = (prop) => (item) => {
    setValue({
      ...values,
      [prop]: item.id.toString(),
      main_from: item.name,
      main_to: item.stop_place
    });
  };

  const formatResult = (item) => {
    return (
      <>
        <span style={{ display: 'block', textAlign: 'left' }}>
          {item.name} - {item.stop_place}
        </span>
      </>
    );
  };

  const getUpdateDatas = (id) => {
    console.log(states);
    const data = states.routeDatas?.data.filter((v) => v.id === id);
    setIsEdit(true);
    setID(id);
    setValue({
      ...values,
      name: data[0].name,
      city_id: data[0].city_id,
      price: data[0].price,
      from: data[0].from,
      to: data[0].to,
      time_estimate: data[0].time_estimate
    });
    setShowModal(true);
  };

  const submitData = () => {
    isEdit ? updateRoutes() : createRoute();
  };

  const deleteRoute = (v) => {
    let text = `Apakah anda akan menghapus ${v.name} dari daftar destinasi?`;
    if (confirm(text) == true) {
      dispatch(removeRoute(v.id));
    } else {
      console.log('cancel delete');
    }
  };

  const handleChange = (prop) => (event) => {
    setValue({ ...values, [prop]: event.target.value });
  };

  const getRoutes = (skip) => {
    setStartNumber(skip);
    const body = {
      skip
    };
    dispatch(getRouteDatas(body));
  };

  const renderTable = () => {
    return (
      <div className="flex flex-col ">
        <div className="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
          <div className="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">
            <table className="min-w-full">
              <thead>
                <tr>
                  <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-50">
                    No
                  </th>
                  <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-50">
                    Nama Rute
                  </th>
                  <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-50">
                    Berangkat
                  </th>
                  <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-50">
                    Tujuan
                  </th>
                  <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-50 w-1/6">
                    Waktu Tempuh
                  </th>
                  <th className="px-6 py-3 text-xs text-center font-medium leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-50 w-1/6">
                    Harga (Rp)
                  </th>
                  <th className="text-xs text-center font-medium leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-50 w-1/6 text-center">
                    Aksi
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {states.routeDatas &&
                  states.routeDatas?.data.map((v, i) => (
                    <tr key={`datas-${i}`}>
                      <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                        <div className="text-sm font-medium leading-5 text-gray-900">
                          {startNo + i + 1}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                        <div className="text-sm font-medium leading-5 text-gray-900">{v.name}</div>
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                        <div className="text-sm font-medium leading-5 text-gray-900">{v.from}</div>
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                        <div className="text-sm font-medium leading-5 text-gray-900">{v.to}</div>
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                        <div className="text-sm font-medium leading-5 text-gray-900">
                          {v.time_estimate}
                        </div>
                      </td>
                      <td className="flex justify-between px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                        <div className="text-sm font-medium leading-5 text-gray-900 text-left">
                          Rp
                        </div>
                        <div className="text-sm font-medium leading-5 text-gray-900 text-right">
                          {thousandSeparator(v.price)}
                        </div>
                      </td>
                      <td className="text-sm leading-5 text-gray-500 whitespace-no-wrap border-b border-gray-200">
                        <div className="flex justify-center">
                          <svg
                            onClick={() => getUpdateDatas(v.id)}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            className="w-5 h-5 text-blue-400 hover:cursor-pointer mr-2 mt-1"
                            // fill="none"
                            // viewBox="0 0 24 24"
                            fill="currentColor">
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1"
                              d="M29.663,482.25l.087.087a24.847,24.847,0,0,0,17.612,7.342,25.178,25.178,0,0,0,8.1-1.345l142.006-48.172,272.5-272.5A88.832,88.832,0,0,0,344.334,42.039l-272.5,272.5L23.666,456.541A24.844,24.844,0,0,0,29.663,482.25Zm337.3-417.584a56.832,56.832,0,0,1,80.371,80.373L411.5,180.873,331.127,100.5ZM99.744,331.884,308.5,123.127,388.873,203.5,180.116,412.256,58.482,453.518Z"
                            />
                          </svg>
                          <svg
                            onClick={() => deleteRoute(v)}
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-6 h-6 text-red-400 hover:cursor-pointer"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor">
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                            />
                          </svg>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <Pagination datas={states.routeDatas?.total ?? 0} getDatas={(v) => getRoutes(v)} />
          </div>
        </div>
      </div>
    );
  };

  const closeModals = () => {
    setShowModal(!showModal);
    resetState();
    setIsEdit(false);
  };

  const _renderModal = () => {
    return (
      <Modal
        show={showModal}
        setModalClose={() => closeModals()}
        title={`Tambah Rute Perjalanan`}
        width={`w-1/2`}
        onSubmit={() => submitData()}>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Nama Rute</label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="destination"
            type="text"
            placeholder="ex. Pontianak, Sambas etc"
            value={values.name}
            onChange={handleChange('name')}
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Keberangkatan</label>
          <ReactSearchAutocomplete
            items={states.cityDesinationDatas?.data}
            onSelect={handleOnSelect('city_id')}
            formatResult={formatResult}
            placeholder={'Pilih Rute'}
          />
        </div>
        {/* {!isEdit ? (
          <>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">Keberangkatan</label>
              <ReactSearchAutocomplete
                items={states.cityDesinationDatas}
                onSelect={handleOnSelect('from')}
                formatResult={formatResult}
                placeholder={'Pilih Kota Keberangkatan'}
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">Tujuan</label>
              <ReactSearchAutocomplete
                items={states.cityDesinationDatas}
                onSelect={handleOnSelect('to')}
                formatResult={formatResult}
                placeholder={'Pilih Kota Tujuan'}
              />
            </div>
          </>
        ) : (
          <></>
        )} */}
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Berangkat dari</label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="destination"
            type="text"
            placeholder="ex. Bundatran Universitas TunjangParu"
            value={values.from}
            onChange={handleChange('from')}
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Berhenti di</label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="destination"
            type="text"
            placeholder="ex. Halte Depan Puskesmas Sawahan"
            value={values.to}
            onChange={handleChange('to')}
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Waktu Tempuh (Menit)</label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="destination"
            type="text"
            placeholder="ex Bus place, Terminal etc"
            value={values.time_estimate}
            onChange={handleChange('time_estimate')}
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Harga</label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="destination"
            type="text"
            placeholder="ex Bus place, Terminal etc"
            value={values.price}
            onChange={handleChange('price')}
          />
        </div>
      </Modal>
    );
  };

  const _renderBody = () => {
    return (
      <>
        <div className={`text-xl bg-white p-4 rounded-md drop-shadow-lg mt-4 flex justify-end`}>
          <Button
            label={'Tambah Data'}
            type={'primary'}
            cb={() => {
              setIsEdit(false);
              setShowModal(!showModal);
            }}
          />
        </div>
        <div className={`text-xl bg-white p-4 rounded-md drop-shadow-lg mt-4`}>{renderTable()}</div>
      </>
    );
  };

  return (
    <div>
      {_renderBody()} {_renderModal()}
    </div>
  );
}
