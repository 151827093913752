import './index.css';
import { Provider } from 'react-redux';
import { CookiesProvider } from 'react-cookie';

import Store from './Config';
import RootApp from './Pages';
import 'boxicons';

function App() {
  return (
    <CookiesProvider defaultSetCookies={{ path: '/' }}>
      <Provider store={Store}>
        <RootApp />
      </Provider>
    </CookiesProvider>
  );
}

export default App;
