import { call, takeLatest, put, take } from 'redux-saga/effects';
import {
  POST_CREATEADMIN,
  GET_ADMINDATAS,
  DELETE_ADMIN,
  POST_CREATECITY,
  GET_CITYDATAS,
  DELETE_CITY,
  POST_CREATEROUTES,
  GET_ROUTEDATAS,
  DELETE_ROUTE,
  POST_CREATEPARTNERS,
  GET_PARTNERDATAS,
  DELETE_PARTNER,
  POST_CREATEVEHICLE,
  GET_VEHICLERDATAS,
  DELETE_VEHICLE,
  PATCH_VEHICLE,
  UPDATE_ROUTE,
  POST_CREATETICKET,
  GET_TICKETRDATAS,
  DELETE_TICKET,
  POST_UPLOADFILE,
  GET_BANNERDATAS,
  DELETE_BANNER,
  PATCH_BANNER,
  GET_ARTICLEDATAS,
  PATCH_ARTICLE,
  DELETE_ARTICLE,
  POST_CONFIGDATAS,
  GET_CONFIGDATAS,
  PATCH_CONFIG,
  DELETE_CONFIG,
  POST_LOGIN
} from '../Actions/type';
import {
  postAdminDatas,
  postAllPartnerDatas,
  postArticleDatas,
  postBannerDatas,
  postCityDatas,
  postConfigDatas,
  postPartnerDatas,
  postRouteDatas,
  postTicketDatas,
  postVehicleDatas,
  setLogin
} from '../Actions/action';
import Listener from '../Utils/Listen';
import * as API from '../Services/api';

function* workerLogin(action) {
  try {
    // eslint-disable-next-line no-unused-vars

    const response = yield call(API.postLogin, action.payload);

    if (response === undefined) {
      alert('Username / password salah');
    } else {
      yield put(setLogin(response));
    }

    return;
  } catch (error) {
    const payload = {
      status: 'error',
      message: 'Email / Password Salah'
    };
    Listener.emit('createToast', payload);
  }
}

function* workerCreateAdmin(action) {
  try {
    // eslint-disable-next-line no-unused-vars
    const response = yield call(API.createAdmin, action.payload);
    return;
  } catch (error) {
    console.log(error);
  }
}

function* workerGetAdminDatas() {
  try {
    const response = yield call(API.getAllAdmin);
    yield put(postAdminDatas(response.data));
  } catch (error) {
    const payload = {
      status: 'error',
      message: error.response?.data?.errors[0]?.message
    };
    Listener.emit('createToast', payload);
  }
}

function* workerDeleteAdmin(action) {
  try {
    // eslint-disable-next-line no-unused-vars
    const response = yield call(API.deleteAdmin(action.payload));
    const payload = {
      status: 'success',
      message: 'Data berhasil dihapus'
    };
    Listener.emit('createToast', payload);
  } catch (error) {
    const payload = {
      status: 'error',
      message: error.response?.data?.errors[0]?.message
    };
    Listener.emit('createToast', payload);
  }
}

//============= CITY DESTINATION ==========

function* workerCreateCity(action) {
  try {
    // eslint-disable-next-line no-unused-vars
    const response = yield call(API.createCityDestination, action.payload);
    const payload = {
      status: 'success',
      message: 'Data berhasil di tambahkan'
    };
    Listener.emit('createCityResponse', payload);
    Listener.emit('createToast', payload);
  } catch (error) {
    const payload = {
      status: 'error',
      message: error.response?.data?.errors[0]?.message
    };
    Listener.emit('createCityResponse', payload);
    Listener.emit('createToast', payload);
  }
}

function* workerGetCityDatas(payload) {
  try {
    const response = yield call(API.getAllCityDestination, payload.payload);
    yield put(postCityDatas(response));
  } catch (error) {
    const payload = {
      status: 'error',
      message: error.response?.data?.errors[0]?.message
    };
    Listener.emit('createToast', payload);
  }
}

function* workerDeleteCity(action) {
  try {
    // eslint-disable-next-line no-unused-vars
    const response = yield call(API.deleteCityDestination(action.payload));
    const payload = {
      status: 'success',
      message: 'Data berhasil dihapus'
    };
    Listener.emit('createToast', payload);
  } catch (error) {
    const payload = {
      status: 'error',
      message: error.response?.data?.errors[0]?.message
    };
    Listener.emit('createToast', payload);
  }
}

//============= ROUTES TRIP ==========

function* workerCreateRoute(action) {
  try {
    // eslint-disable-next-line no-unused-vars
    const response = yield call(API.createRoute, action.payload);
    return;
  } catch (error) {
    console.log(error.response?.data?.errors[0]?.message);
  }
}

function* workerUpdateRoute(action) {
  try {
    // eslint-disable-next-line no-unused-vars
    const response = yield call(API.updateRoute(action.payload));
    return;
  } catch (error) {
    console.log(error);
  }
}

function* workerGetRoutes(payload) {
  try {
    const response = yield call(API.getAllRoute, payload.payload);
    yield put(postRouteDatas(response));
  } catch (error) {
    const payload = {
      status: 'error',
      message: error.response?.data?.errors[0]?.message
    };
    Listener.emit('createToast', payload);
  }
}

function* workerDeleteRoute(action) {
  try {
    // eslint-disable-next-line no-unused-vars
    const response = yield call(API.deleteRoute(action.payload));
    return;
  } catch (error) {
    console.log(error);
  }
}

//============= PARTNER ==========

function* workerCreatePartner(action) {
  try {
    // eslint-disable-next-line no-unused-vars
    const response = yield call(API.createPartner, action.payload);
    return;
  } catch (error) {
    console.log(error);
    return;
  }
}

function* workerGetPartner(action) {
  try {
    const response = yield call(API.getAllPartner, action.payload);
    // if (action.payload.allDatas) {
    //   yield put(postAllPartnerDatas(response));
    // } else {
    yield put(postPartnerDatas(response));
    // }
  } catch (error) {
    const payload = {
      status: 'error',
      message: error.response?.data?.errors[0]?.message
    };
    Listener.emit('createToast', payload);
  }
}

function* workerDeletePartner(action) {
  try {
    // eslint-disable-next-line no-unused-vars
    const response = yield call(API.deletePartner(action.payload));
    return;
  } catch (error) {
    console.log(error);
    return;
  }
}

//============= VEHICLE ==========

function* workerCreateVehicle(action) {
  try {
    // eslint-disable-next-line no-unused-vars
    const response = yield call(API.createVehicle, action.payload);
    return;
  } catch (error) {
    console.log(error);
    return;
  }
}

function* workerGetVehicle(payload) {
  try {
    const response = yield call(API.getAllVehicle, payload.payload);
    yield put(postVehicleDatas(response));
  } catch (error) {
    const payload = {
      status: 'error',
      message: error.response?.data?.errors[0]?.message
    };
    Listener.emit('createToast', payload);
  }
}

function* workerPatchVehicle(action) {
  try {
    // eslint-disable-next-line no-unused-vars
    const response = yield call(API.patchVehicle(action.payload));
    return;
  } catch (error) {
    console.log(error);
    return;
  }
}

function* workerDeleteVehicle(action) {
  try {
    // eslint-disable-next-line no-unused-vars
    const response = yield call(API.deleteVehicle(action.payload));
    return;
  } catch (error) {
    console.log(error);
    return;
  }
}

//=================== TICKET =========

function* workerCreateTicket(action) {
  try {
    // eslint-disable-next-line no-unused-vars
    const response = yield call(API.createTicket, action.payload);
    return;
  } catch (error) {
    console.log(error);
    return;
  }
}

function* workerGetTicket(payload) {
  try {
    const response = yield call(API.getAllTicket, payload.payload);
    yield put(postTicketDatas(response));
  } catch (error) {
    const payload = {
      status: 'error',
      message: error.response?.data?.errors[0]?.message
    };
    Listener.emit('createToast', payload);
  }
}

function* workerDeleteTicket(action) {
  try {
    // eslint-disable-next-line no-unused-vars
    const response = yield call(API.deleteTicket(action.payload));
    return;
  } catch (error) {
    console.log(error);
    return;
  }
}

//=================== UPLAOD FILE =========

function* workerUploadFile(action) {
  try {
    // eslint-disable-next-line no-unused-vars
    const response = yield call(API.uploadFile, action.payload);
    const payload = {
      status: 'success',
      message: 'Data berhasil di tambahkan'
    };
    Listener.emit('listenPartner', payload);
    Listener.emit('createToast', payload);
  } catch (error) {
    console.log(error);
    console.log(error.response?.data?.errors[0]?.message);
    const payload = {
      status: 'error',
      message: error.response?.data?.errors[0]?.message
    };
    Listener.emit('listenPartner', payload);
    Listener.emit('createToast', payload);
  }
}

//================ BANNER ===========
function* workerGetBanner() {
  try {
    const response = yield call(API.getAllBanner);

    yield put(postBannerDatas(response.data));
  } catch (error) {
    console.log(error);
    return;
  }
}

function* workerPatchBanner(action) {
  try {
    // eslint-disable-next-line no-unused-vars
    const response = yield call(API.patcBanner(action.payload));
    return;
  } catch (error) {
    console.log(error);
    return;
  }
}

function* workerDeleteBanner(action) {
  try {
    const response = yield call(API.deleteBanner(action.payload));

    return;
  } catch (error) {
    console.log(error);
    return;
  }
}

//================ ARTICLE ===========
function* workerGetArticle(payload) {
  try {
    const response = yield call(API.getAllArticle, payload.payload);

    yield put(postArticleDatas(response));
  } catch (error) {
    console.log(error);
    return;
  }
}

function* workerPatchArticle(action) {
  try {
    // eslint-disable-next-line no-unused-vars
    const response = yield call(API.patchArticle(action.payload));
    return;
  } catch (error) {
    console.log(error);
    return;
  }
}

function* workerDeleteArticle(action) {
  try {
    const response = yield call(API.deleteArticle(action.payload));

    return;
  } catch (error) {
    console.log(error);
    return;
  }
}

// ============ CONFIG DATAS =====================

function* workerGetConfigDatas(action) {
  try {
    const response = yield call(API.getAllConfig);

    yield put(postConfigDatas(response.data));
  } catch (error) {
    console.log(error);
    return;
  }
}

function* workerPatchConfiguration(action) {
  try {
    // eslint-disable-next-line no-unused-vars
    const response = yield call(API.patchConfig(action.payload));

    return;
  } catch (error) {
    console.log(error);
    return;
  }
}

function* workerDeleteConfig(action) {
  try {
    const response = yield call(API.deleteConfig(action.payload));
    return;
  } catch (error) {
    console.log(error);
    return;
  }
}

function* watcherAllWorker() {
  yield takeLatest(POST_CREATEADMIN, workerCreateAdmin);
  yield takeLatest(GET_ADMINDATAS, workerGetAdminDatas);
  yield takeLatest(DELETE_ADMIN, workerDeleteAdmin);
  yield takeLatest(POST_CREATECITY, workerCreateCity);
  yield takeLatest(GET_CITYDATAS, workerGetCityDatas);
  yield takeLatest(DELETE_CITY, workerDeleteCity);
  yield takeLatest(POST_CREATEROUTES, workerCreateRoute);
  yield takeLatest(UPDATE_ROUTE, workerUpdateRoute);
  yield takeLatest(GET_ROUTEDATAS, workerGetRoutes);
  yield takeLatest(DELETE_ROUTE, workerDeleteRoute);
  yield takeLatest(POST_CREATEPARTNERS, workerCreatePartner);
  yield takeLatest(GET_PARTNERDATAS, workerGetPartner);
  yield takeLatest(DELETE_PARTNER, workerDeletePartner);
  yield takeLatest(POST_CREATEVEHICLE, workerCreateVehicle);
  yield takeLatest(GET_VEHICLERDATAS, workerGetVehicle);
  yield takeLatest(PATCH_VEHICLE, workerPatchVehicle);
  yield takeLatest(DELETE_VEHICLE, workerDeleteVehicle);
  yield takeLatest(POST_CREATETICKET, workerCreateTicket);
  yield takeLatest(GET_TICKETRDATAS, workerGetTicket);
  yield takeLatest(DELETE_TICKET, workerDeleteTicket);
  yield takeLatest(POST_UPLOADFILE, workerUploadFile);
  yield takeLatest(GET_BANNERDATAS, workerGetBanner);
  yield takeLatest(PATCH_BANNER, workerPatchBanner);
  yield takeLatest(DELETE_BANNER, workerDeleteBanner);
  yield takeLatest(GET_ARTICLEDATAS, workerGetArticle);
  yield takeLatest(PATCH_ARTICLE, workerPatchArticle);
  yield takeLatest(DELETE_ARTICLE, workerDeleteArticle);
  yield takeLatest(GET_CONFIGDATAS, workerGetConfigDatas);
  yield takeLatest(PATCH_CONFIG, workerPatchConfiguration);
  yield takeLatest(DELETE_CONFIG, workerDeleteConfig);
  yield takeLatest(POST_LOGIN, workerLogin);
}

export default watcherAllWorker;
