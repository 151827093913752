import Artikel from '../Pages/Artikel';
import Banner from '../Pages/Banners';
import Kota from '../Pages/Cities';
import Rute from '../Pages/Routes';
import Tiket from '../Pages/Tickets';
import Partner from '../Pages/Partner';
import Kendaraan from '../Pages/Vehicle';
import Rental from '../Pages/Rent';
import PemesananTiket from '../Pages/Ticket-order';
import Admin from '../Pages/Admin';
import Configuration from '../Pages/Config';

const sideBarMenu = [
  // {
  //   id: 1,
  //   label: 'Dashboard',
  //   url: '/dashboard',
  //   icon: 'bxs-dashboard'
  //   // 'page' : Dashboard
  // },
  {
    id: 1,
    label: 'Artikel',
    url: '/artikel',
    icon: 'bxs-edit-alt',
    page: Artikel
  },
  {
    id: 2,
    label: 'Banner',
    url: '/banner',
    icon: 'bxs-image-add',
    page: Banner
  },
  {
    id: 3,
    label: 'Daftar Kota',
    url: '/kota',
    icon: 'bxs-city',
    page: Kota
  },
  {
    id: 4,
    label: 'Rute Pejalanan',
    url: '/rute',
    icon: 'bxs-directions',
    page: Rute
  },
  {
    id: 5,
    label: 'Tiket',
    url: '/tiket',
    icon: 'bxs-credit-card-front',
    page: Tiket
  },
  {
    id: 6,
    label: 'Partner',
    url: '/partner',
    icon: 'bxs-game',
    page: Partner
  },
  {
    id: 7,
    label: 'Daftar Kendaraan',
    url: '/kendaraan',
    icon: 'bxs-car',
    page: Kendaraan
  },
  // {
  //   id: 8,
  //   label: 'Rental Kendaraan',
  //   url: '/rental',
  //   icon: 'bxs-cart-download',
  //   page: Rental
  // },
  // {
  //   id: 9,
  //   label: 'Pemesanan Tiket',
  //   url: '/pemesanantiket',
  //   icon: 'bxs-cart-download',
  //   page: PemesananTiket
  // },
  {
    id: 10,
    label: 'Configuration',
    url: '/config',
    icon: 'bxs-cog',
    page: Configuration
  },
  {
    id: 11,
    label: 'Administrator',
    url: '/admin',
    icon: 'bxs-user-plus',
    page: Admin
  }
];

export { sideBarMenu };
