import {
  POST_LOGIN,
  SET_LOGIN,
  POST_CREATEADMIN,
  GET_ADMINDATAS,
  POST_ADMINDATAS,
  DELETE_ADMIN,
  POST_CREATECITY,
  GET_CITYDATAS,
  POST_CITYDATAS,
  DELETE_CITY,
  POST_CREATEROUTES,
  GET_ROUTEDATAS,
  POST_ROUTEDATAS,
  DELETE_ROUTE,
  POST_CREATEPARTNERS,
  GET_PARTNERDATAS,
  POST_PARTNERDATAS,
  DELETE_PARTNER,
  POST_CREATEVEHICLE,
  GET_VEHICLERDATAS,
  POST_VEHILCEDATAS,
  DELETE_VEHICLE,
  PATCH_VEHICLE,
  UPDATE_ROUTE,
  POST_CREATETICKET,
  GET_TICKETRDATAS,
  POST_TICKETDATAS,
  DELETE_TICKET,
  POST_CREATEBANNER,
  GET_BANNERDATAS,
  POST_BANNERDATAS,
  DELETE_BANNER,
  POST_UPLOADFILE,
  PATCH_BANNER,
  GET_ARTICLEDATAS,
  POST_ARTICLEDATAS,
  PATCH_ARTICLE,
  DELETE_ARTICLE,
  POST_CREATECONFIG,
  GET_CONFIGDATAS,
  POST_CONFIGDATAS,
  DELETE_CONFIG,
  PATCH_CONFIG
} from './type';

const postLogin = (payload) => {
  return {
    type: POST_LOGIN,
    payload
  };
};

const setLogin = (payload) => {
  return {
    type: SET_LOGIN,
    payload
  };
};

// ADMINIOTRATOR

const postCreateAdmin = (payload) => {
  return {
    type: POST_CREATEADMIN,
    payload
  };
};

const getAdminDatas = () => {
  return {
    type: GET_ADMINDATAS
  };
};

const postAdminDatas = (payload) => {
  return {
    type: POST_ADMINDATAS,
    payload
  };
};

const removeAdmin = (payload) => {
  return {
    type: DELETE_ADMIN,
    payload
  };
};

// CITY DESTINATION

const postCreateCity = (payload) => {
  return {
    type: POST_CREATECITY,
    payload
  };
};

const getCityDatas = (payload) => {
  return {
    type: GET_CITYDATAS,
    payload
  };
};

const postCityDatas = (payload) => {
  return {
    type: POST_CITYDATAS,
    payload
  };
};

const removeCity = (payload) => {
  return {
    type: DELETE_CITY,
    payload
  };
};

// ROUTES

const postCreateRoute = (payload) => {
  return {
    type: POST_CREATEROUTES,
    payload
  };
};

const getRouteDatas = (payload) => {
  return {
    type: GET_ROUTEDATAS,
    payload
  };
};

const updateRoute = (payload) => {
  return {
    type: UPDATE_ROUTE,
    payload
  };
};

const postRouteDatas = (payload) => {
  return {
    type: POST_ROUTEDATAS,
    payload
  };
};

const removeRoute = (payload) => {
  return {
    type: DELETE_ROUTE,
    payload
  };
};

// PARTNER

const postCreatePartner = (payload) => {
  return {
    type: POST_CREATEPARTNERS,
    payload
  };
};

const getPartnerDatas = (payload) => {
  return {
    type: GET_PARTNERDATAS,
    payload
  };
};

const postPartnerDatas = (payload) => {
  return {
    type: POST_PARTNERDATAS,
    payload
  };
};

const postAllPartnerDatas = (payload) => {
  console.log('FROM PAYLOAD', payload);
  return {
    type: POST_ALL_PARTNERDATAS,
    payload
  };
};

const removePartner = (payload) => {
  return {
    type: DELETE_PARTNER,
    payload
  };
};

// VEHICLE

const postCreateVehicle = (payload) => {
  return {
    type: POST_CREATEVEHICLE,
    payload
  };
};

const getVehicleDatas = (payload) => {
  return {
    type: GET_VEHICLERDATAS,
    payload
  };
};

const postVehicleDatas = (payload) => {
  return {
    type: POST_VEHILCEDATAS,
    payload
  };
};

const patchVehicle = (payload) => {
  return {
    type: PATCH_VEHICLE,
    payload
  };
};

const removeVehicle = (payload) => {
  return {
    type: DELETE_VEHICLE,
    payload
  };
};

//TICKET
const postCreateTicket = (payload) => {
  return {
    type: POST_CREATETICKET,
    payload
  };
};

const getTicketDatas = (payload) => {
  return {
    type: GET_TICKETRDATAS,
    payload
  };
};

const postTicketDatas = (payload) => {
  return {
    type: POST_TICKETDATAS,
    payload
  };
};

const removeTicket = (payload) => {
  return {
    type: DELETE_TICKET,
    payload
  };
};

// BANNER
const postCreateBanner = (payload) => {
  return {
    type: POST_CREATEBANNER,
    payload
  };
};

const getBannerDatas = () => {
  return {
    type: GET_BANNERDATAS
  };
};

const postBannerDatas = (payload) => {
  return {
    type: POST_BANNERDATAS,
    payload
  };
};

const patchBanner = (payload) => {
  return {
    type: PATCH_BANNER,
    payload
  };
};

const removeBanner = (payload) => {
  return {
    type: DELETE_BANNER,
    payload
  };
};

// ARTICLE

const getArticleDatas = (payload) => {
  return {
    type: GET_ARTICLEDATAS,
    payload
  };
};

const postArticleDatas = (payload) => {
  return {
    type: POST_ARTICLEDATAS,
    payload
  };
};

const patchArticle = (payload) => {
  return {
    type: PATCH_ARTICLE,
    payload
  };
};

const removeArticle = (payload) => {
  return {
    type: DELETE_ARTICLE,
    payload
  };
};

// Upload File
const postUploadFile = (payload) => {
  return {
    type: POST_UPLOADFILE,
    payload
  };
};

// CONFIGURATION

const postCreateConfig = (payload) => {
  return {
    type: POST_CREATECONFIG,
    payload
  };
};

const getConfigDatas = () => {
  return {
    type: GET_CONFIGDATAS
  };
};

const postConfigDatas = (payload) => {
  return {
    type: POST_CONFIGDATAS,
    payload
  };
};

const patchConfig = (payload) => {
  return {
    type: PATCH_CONFIG,
    payload
  };
};

const removeConfig = (payload) => {
  return {
    type: DELETE_CONFIG,
    payload
  };
};

export {
  postLogin,
  setLogin,
  postCreateAdmin,
  getAdminDatas,
  postAdminDatas,
  removeAdmin,
  postCreateCity,
  getCityDatas,
  postCityDatas,
  removeCity,
  postCreateRoute,
  getRouteDatas,
  updateRoute,
  postRouteDatas,
  removeRoute,
  postCreatePartner,
  getPartnerDatas,
  postPartnerDatas,
  removePartner,
  postCreateVehicle,
  getVehicleDatas,
  postVehicleDatas,
  patchVehicle,
  removeVehicle,
  postCreateTicket,
  getTicketDatas,
  postTicketDatas,
  removeTicket,
  postCreateBanner,
  getBannerDatas,
  postBannerDatas,
  removeBanner,
  postUploadFile,
  patchBanner,
  getArticleDatas,
  postArticleDatas,
  patchArticle,
  removeArticle,
  postCreateConfig,
  getConfigDatas,
  patchConfig,
  removeConfig,
  postConfigDatas,
  postAllPartnerDatas
};
