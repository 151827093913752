import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getConfigDatas, patchConfig, removeConfig } from '../../Actions/action';
import { Button, Modal } from '../../Components';
import Listen from '../../Utils/Listen';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const URL = 'https://borneotrans-api.amazingborneo.id';
// const URL = 'http://localhost:3030/'

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    ['link'],
    ['clean']
  ]
};

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image'
];

export default function index() {
  const dispatch = useDispatch();
  const states = useSelector((states) => states.MainReducers);
  const [showModal, setShowModal] = React.useState(false);
  const [values, setValue] = React.useState({
    name: '',
    no_hp_1: '',
    no_hp_2: '',
    email: '',
    logo: '',
    address: '',
    about_us: '',
    coordinat: '',
    social_media: '',
    ig: '',
    fb: '',
    x: '',
    file: null,
    folder: 'config'
  });
  const [refresh, setRefresh] = React.useState(null);
  const [aboutUs, setAboutUs] = React.useState('');

  React.useEffect(() => {
    dispatch(getConfigDatas());
    // setRefresh(Math.random());
  }, [refresh]);

  React.useEffect(() => {
    if (states.configDatas !== null) {
      setValue({
        ...values,
        name: states?.configDatas[0].name ?? '',
        no_hp_1: states?.configDatas[0].no_hp_1 ?? '',
        no_hp_2: states?.configDatas[0].no_hp_2 ?? '',
        email: states?.configDatas[0].email ?? '',
        logo: states?.configDatas[0].logo ?? '',
        address: states?.configDatas[0].address ?? '',
        about_us: '',
        coordinat: '',
        social_media: states?.configDatas[0].social_media ?? '',
        ig: JSON.parse(states?.configDatas[0].social_media)[0].values,
        fb: JSON.parse(states?.configDatas[0].social_media)[1].values,
        x: JSON.parse(states?.configDatas[0].social_media)[2].values,
        file: null,
        folder: 'config'
      });
      setAboutUs(states?.configDatas[0].about_us);
    }
  }, [states]);

  React.useEffect(() => {
    Listen.addListener('refresh', eventResponse);
    return () => Listen.removeListener('refresh', eventResponse);
  }, []);

  const eventResponse = (payload) => {
    if (payload.status !== 'error') {
      setRefresh(Math.random());
      closeModal();
    }
  };

  const resetState = () => {
    setValue({
      name: '',
      no_hp_1: '',
      no_hp_2: '',
      email: '',
      logo: '',
      address: '',
      about_us: '',
      coordinat: '',
      social_media: '',
      file: null,
      folder: 'config'
    });
  };

  const closeModal = () => {
    setShowModal(false);
    resetState();
  };

  const updateDetail = () => {
    const sosmed = [
      {
        id: 1,
        initial: 'ig',
        values: values.ig
      },
      {
        id: 2,
        initial: 'fb',
        values: values.fb
      },
      {
        id: 3,
        initial: 'x',
        values: values.x
      }
    ];

    const body = {
      id: 1,
      payload: {
        name: values.name,
        no_hp_1: values.no_hp_1,
        no_hp_2: values.no_hp_2,
        email: values.email,
        address: values.address,
        coordinat: values.coordinat,
        social_media: JSON.stringify(sosmed)
      }
    };
    dispatch(patchConfig(body));
  };

  const updateAboutUs = () => {
    const body = {
      id: 1,
      payload: {
        about_us: aboutUs
      }
    };
    dispatch(patchConfig(body));
  };

  const updateImage = (logo) => {
    const body = {
      id: 1,
      payload: {
        logo: logo
      }
    };
    dispatch(patchConfig(body));
  };

  const createConfiguration = () => {
    let formData = new FormData();
    formData.append('file', values.file[0]);
    formData.append('name', values.name);
    formData.append('email', values.email);
    formData.append('no_hp_1', values.no_hp_1);
    formData.append('no_hp_2', values.no_hp_2);
    formData.append('address', values.address);
    formData.append('about_us', values.about_us);
    formData.append('coordinat', values.coordinat);
    formData.append('social_media', values.social_media);
    formData.append('folder', values.folder);
    fetch(`${URL}/file-upload`, {
      method: 'POST',
      body: formData
    })
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        updateImage(result.data.datas.logo);
        deleteDatas(result.data.datas.id);
        const payload = {
          status: 'success',
          message: 'Banner berhasil di tambahkan'
        };
        Listen.emit('refresh', payload);
        Listen.emit('createToast', payload);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const deleteDatas = (v) => {
    dispatch(removeConfig(v));
  };

  const handleChange = (prop) => (event) => {
    setValue({ ...values, [prop]: event.target.value });
  };

  const handleChangeFile = (event) => {
    setValue({ ...values, file: event.target.files });
  };

  const _renderBody = () => {
    return (
      <>
        {/* <div className={`text-xl bg-white p-4 rounded-md drop-shadow-lg mt-4 flex justify-end`}>
          <Button label={'Tambah Data'} type={'primary'} cb={() => setShowModal(!showModal)} />
        </div> */}
        <div className={`text-xl bg-white p-4 rounded-md drop-shadow-lg mt-4`}>
          <div className="flex flex-row justify-around">
            <div className="p-4 w-1/2">
              <div className="mb-4">
                <img
                  src={`${URL}/uploads/config/${values.logo}`}
                  alt={'v.alt'}
                  loading="lazy"
                  className={'w-full bg-slate-300 rounded'}
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">Logo</label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type="file"
                  name="files"
                  onChange={handleChangeFile}
                />
              </div>
              <Button
                label="Simpan Logo"
                extraClass="w-full"
                type="primary"
                cb={() => createConfiguration()}
              />

              <div className="mb-4 mt-8">
                <label className="block text-gray-700 text-sm font-bold mb-2">Tentang Kami</label>
                <ReactQuill
                  theme="snow"
                  value={aboutUs}
                  onChange={setAboutUs}
                  formats={formats}
                  modules={modules}
                />
              </div>
              <Button
                label="Simpan Tentang Kami"
                extraClass="w-full"
                type="primary"
                cb={() => updateAboutUs()}
              />
            </div>
            <div className="p-4">
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">Nama</label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="destination"
                  type="text"
                  placeholder="ex. Borneo Trans"
                  value={values.name}
                  onChange={handleChange('name')}
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">Email</label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="destination"
                  type="text"
                  placeholder="ex. borneo@borneo.com"
                  value={values.email}
                  onChange={handleChange('email')}
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">No HP 1</label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="destination"
                  type="text"
                  placeholder="ex. 6285705574567"
                  value={values.no_hp_1}
                  onChange={handleChange('no_hp_1')}
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">No HP 2</label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="destination"
                  type="text"
                  placeholder="ex. 6285705574561"
                  value={values.no_hp_2}
                  onChange={handleChange('no_hp_2')}
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">Alamat</label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="destination"
                  type="text"
                  placeholder="ex. Jalan Nusantara"
                  value={values.address}
                  onChange={handleChange('address')}
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">Instagram</label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="destination"
                  type="text"
                  placeholder="intagram"
                  value={values.ig}
                  onChange={handleChange('ig')}
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">Facebook</label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="destination"
                  type="text"
                  placeholder="Travel Jaya Selamat"
                  value={values.fb}
                  onChange={handleChange('fb')}
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">X</label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="destination"
                  type="text"
                  placeholder="traveljy"
                  value={values.x}
                  onChange={handleChange('x')}
                />
              </div>
              <Button
                label="Simpan Detail"
                extraClass="w-full"
                type="primary"
                cb={() => updateDetail()}
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div>
      {_renderBody()}

      {/* <div className="flex flex-row">
        <div></div>
        <div></div>
      </div> */}
    </div>
  );
}
